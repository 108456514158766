import { createReducer } from 'redux-act';

import {
  API_FETCH_DATA_INIT,
  API_FETCH_DATA_LOADING,
  API_FETCH_DATA_SUCCESS,
  API_FETCH_DATA_FAIL,
  API_SUBMIT_DATA_INIT,
  API_SUBMIT_DATA_LOADING,
  API_SUBMIT_DATA_SUCCESS,
  API_SUBMIT_DATA_FAIL,
  API_DELETE_DATA_INIT,
  API_DELETE_DATA_FAIL,
  API_DELETE_DATA_SUCCESS,
  API_MODIFY_DATA_INIT,
  API_MODIFY_DATA_FAIL,
  API_MODIFY_DATA_SUCCESS,
  API_MODIFY_DATA_LOADING,
  API_CLEAN_UP,
} from 'state/actions/api';

const initialState = {
  list: [],
  loading: false,
  artwork: [],
  uploading: false,
  error: {
    errors: null,
    messsage: '',
  },
  deleted: false,
  edited: false,
};

export const apiReducer = createReducer(
  {
    [API_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
      uploading: false,
    }),
    [API_FETCH_DATA_LOADING]: (state, payload) => ({
      ...state,
      loading: true,
    }),
    [API_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      artwork: payload.artwork || [],
      uploading: false,
      loading: false,
      deleted: false,
      error: {
        errors: null,
        messsage: '',
      },
    }),
    [API_DELETE_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
      deleted: false,
    }),
    [API_DELETE_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        messsage: payload.error,
      },
    }),
    [API_DELETE_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      artwork: state.artwork.filter(
        (item) => !payload.data.list.includes(item._id)
      ),
      loading: false,
      error: {
        errors: null,
        messsage: '',
      },
      deleted: true,
    }),
    [API_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [API_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [API_SUBMIT_DATA_INIT]: (state, payload) => ({
      ...state,
      loading: true,
    }),
    [API_SUBMIT_DATA_LOADING]: (state, payload) => ({
      ...state,
      loading: true,
    }),
    [API_SUBMIT_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      uploading: true,
      error: {
        errors: null,
        messsage: '',
      },
    }),
    [API_SUBMIT_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [API_MODIFY_DATA_INIT]: (state, payload) => ({
      ...state,
      loading: true,
      edited: false,
    }),
    [API_MODIFY_DATA_LOADING]: (state, payload) => ({
      ...state,
      loading: true,
      edited: false,
    }),
    [API_MODIFY_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      uploading: true,
      edited: true,
      error: {
        errors: null,
        messsage: '',
      },
    }),
    [API_MODIFY_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
      edited: false,
    }),
  },
  initialState
);
