import React, { useEffect, useState } from 'react';
import { Modal, Button, Collapse } from 'antd';
import './MediaLibrary.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  bulkMedia,
  deleteMedia,
  fetchMedia,
  bulkDeleteMedia,
} from 'state/actions/media';
import { imgUrl } from 'utils/url';
import { useParams } from 'react-router-dom';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { regexInput } from 'utils/regex';
import { fetchMediaCategories } from 'state/actions/mediaCategories';
import PlayListGrid from './PlayListGrid';
import { toastr } from 'react-redux-toastr';

const { Panel } = Collapse;

function Media() {
  const { siteId, siteName } = useParams();
  const [search, setSearch] = useState();
  const [error, setError] = useState(null);
  const [selectedMediaTags, setSelectedMediaTags] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    data: null,
  });

  const { mediaList, loading, updated, categoriesList, deleted } = useSelector(
    (state) => ({
      mediaList: state.media.list,
      categoriesList: state.mediaCategories.list,
      loading: state.media.loading,
      updated: state.media.updated,
      deleted: state.media.deleted,
    }),
    shallowEqual
  );

  const [mediaPreviewModal, setMediaPreviewModal] = useState({
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMedia({ siteId }));
    dispatch(fetchMediaCategories({ siteId }));
    // setMedias(mediaList);
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading && deleted) {
      dispatch(fetchMedia({ siteId }));
    }
  }, [loading, deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading && updated) {
      dispatch(fetchMedia({ siteId }));
    }
  }, [loading, updated]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemoveButtonClickHandler = (mediaId) => {
    Modal.confirm({
      title: 'Are you sure want to delete this data?',
      content: 'Data cannot be restored after delete',
      onOk: () => dispatch(deleteMedia({ id: mediaId, siteId })),
      okButtonProps: {
        loading,
      },
    });
  };

  const data = search
    ? mediaList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : mediaList;

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const onDeleteButtonHandler = () => {
    if (selectedMediaTags.length < 1) {
      toastr.error('', 'Choose the item');
    } else {
      setDeleteModal((prevState) => ({
        ...prevState,
        data: selectedMediaTags.map((item) => item._id),
        isOpen: true,
      }));
    }
  };

  const handleSelectedMedia = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedMediaTags, tag]
      : selectedMediaTags.filter((t) => t !== tag);
    setSelectedMediaTags(nextSelectedTags);
  };

  const hidePreviewModal = () => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const onPreviewModalClickHandler = (media) => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: true,
      media,
    }));
  };

  const onBulkDeleteMediaHandler = () => {
    dispatch(bulkDeleteMedia({ siteId, data: deleteModal.data }));
    setDeleteModal((prevState) => ({
      ...prevState,
      isOpen: false,
      data: null,
    }));
  };

  const onCloseDeleteModalHandler = () => {
    setDeleteModal({
      isOpen: false,
      data: null,
    });
  };

  return (
    <div className="media-library">
      {deleteModal.isOpen && (
        <Modal
          title="
          Are you sure want to delete these data?
          "
          visible={deleteModal.isOpen}
          onOk={onBulkDeleteMediaHandler}
          onCancel={onCloseDeleteModalHandler}
        >
          Data cannot be restored after delete
        </Modal>
      )}

      {mediaPreviewModal.isOpen && (
        <Modal
          title={null}
          visible={mediaPreviewModal.isOpen}
          footer={null}
          onCancel={hidePreviewModal}
          className="media-preview-modal custom-modal"
        >
          {mediaPreviewModal.media.logo &&
          mediaPreviewModal.media.fileType.split('/')[0] === 'image' ? (
            <img
              src={`${imgUrl}/media/${mediaPreviewModal.media.logo}`}
              alt="post"
            />
          ) : mediaPreviewModal.media.logo &&
            mediaPreviewModal.media.fileType.split('/')[0] === 'video' ? (
            <video id="my-video" className="video-js" data-setup="{}" controls>
              <source src={`${imgUrl}/media/${mediaPreviewModal.media.logo}`} />
            </video>
          ) : null}

          <div className="custom-modal-content">
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Title</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.title ? mediaPreviewModal.title : 'No Title'}
              </div>
            </div>
            <div className="custom-modal-content-title">
              <div className="custom-modal-title">Description</div>
              <div className="custom-modal-description">
                {mediaPreviewModal.description}
              </div>
            </div>
          </div>

          {mediaPreviewModal.media.description && (
            <p>{mediaPreviewModal.media.description}</p>
          )}
        </Modal>
      )}

      <div className="media-header">
        <div className="searchbar-wrapper">
          <div className="searchbar">
            <i className="feather-search" />
            <input
              placeholder="Search..."
              className="search-input"
              value={search}
              onChange={handleSearch}
            />
          </div>
          {error && <div className="search-error">{error}</div>}
        </div>
        <div className="actions__wrapper">
          <Button
            type="danger"
            className="btn btn-danger media__delete"
            onClick={onDeleteButtonHandler}
          >
            Delete Media
          </Button>
          <Button className="btn-primary">
            <Link to={`/${siteId}/information/${siteName}/media/new`}>
              New Media
            </Link>
          </Button>
        </div>
      </div>

      <Collapse className="media-library__wrapper">
        {Array.from(
          Object.keys(_.groupBy(categoriesList, '_id')).map(
            // eslint-disable-next-line
            (categoryId, index) => {
              const groupedMedia = _.groupBy(data, 'categoryId');

              if (
                data.length > 0 &&
                data.find((item) => item.categoryId === categoryId)
              ) {
                return (
                  <Panel
                    key={index}
                    header={
                      categoriesList.find(
                        (category) => category._id === categoryId
                      ).name
                    }
                    className="media-library__header"
                  >
                    <div className="media-library__panel">
                      <PlayListGrid
                        mediaList={_.sortBy(
                          groupedMedia[categoryId],
                          'sequence'
                        )}
                        distance={1}
                        axis="xy"
                        onSortEnd={({ oldIndex, newIndex }) => {
                          const items = _.groupBy(mediaList, 'categoryId');

                          const [reorderedItem] = items[categoryId].splice(
                            oldIndex,
                            1
                          );
                          items[categoryId].splice(newIndex, 0, reorderedItem);

                          const newItems = Object.values(items).flat();
                          dispatch(bulkMedia({ data: newItems, siteId }));
                        }}
                        onPreviewModalClickHandler={onPreviewModalClickHandler}
                        onRemoveButtonClickHandler={onRemoveButtonClickHandler}
                        handleSelectedMedia={handleSelectedMedia}
                        selectedMediaTags={selectedMediaTags}
                      />
                    </div>
                  </Panel>
                );
              }
            }
          )
        )}
      </Collapse>
    </div>
  );
}

export default Media;
