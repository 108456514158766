import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
import MapForm from 'components/MapForm';
import { createMaps, modifyMaps, fetchMaps } from 'state/actions/maps';
import { useFormatMessage } from 'hooks';

const Map = ({ history }) => {
  const { id, siteId } = useParams();

  const { success, mapList, mapData, sitesList, error, loading } = useSelector(
    (state) => ({
      success: state.maps.success,
      mapList: state.maps.list,
      mapData: state.maps.map,
      sitesList: state.sites.list,
      error: state.maps.error,
      loading: state.maps.loading,
    }),
    shallowEqual
  );

  const [map, setMap] = useState(mapData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const mapFetched = mapList.find((fetchedMap) => fetchedMap._id === id);
      setMap(mapFetched);
    } else if (mapData && mapData._id === id) {
      setMap(mapData);
    } else {
      dispatch(fetchMaps({ siteId: siteId }));
    }
  }, [id, mapData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEditing = !!id;

  const mapForm =
    !map && id ? (
      <ClipLoader />
    ) : (
      <MapForm
        isEditing={isEditing}
        map={map}
        sites={sitesList}
        setMap={setMap}
        action={isEditing ? modifyMaps : createMaps}
        history={history}
        success={success}
        id={id}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editMapMessage = useFormatMessage('Maps.editMap');

  const newMapMessage = useFormatMessage('Maps.newMap');

  return (
    <>
      {log(loading, id, siteId, mapData)}
      {/* {redirect} */}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editMapMessage : newMapMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">{mapForm}</section>
    </>
  );
};

export default Map;
