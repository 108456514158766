/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Input, Modal } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { categoriesCleanUp } from 'state/actions/categories';
import { regexDescription, regexInput } from 'utils/regex';
import './ModalCategoryForm.scss';
import { log } from 'utils';

const ModalCategoryForm = ({
  category,
  setCategory,
  visible,
  isEditing,
  onCancel,
  onCreate,
}) => {
  const [form] = Form.useForm();

  const { loading, error } = useSelector(
    (state) => ({
      loading: state.categories.loading,
      error: state.categories.error,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState(error);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(categoriesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setCategory);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(values.name) &&
        values.name && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(values.description) &&
        values.description && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];
      setErrors(newErrors);
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...category,
        id: category._id,
        name: values.name,
        description: values.description,
      });
      form.resetFields();
    }
  };

  return (
    <Modal
      visible={visible}
      title={isEditing ? 'Edit Category' : 'Create New Category'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: category.name,
          description: category.description,
        }}
      >
        {log(loading, 'loading')}
        <Form.Item label="Name" name="name" required>
          <Input />
        </Form.Item>
        {errors &&
          errors.errors &&
          errors.errors.name &&
          errors.errors.name.map((name, idx) => (
            <div className="error-message" key={idx}>
              {name.msg}
            </div>
          ))}

        <Form.Item label="Description" name="description" required>
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
        {errors &&
          errors.errors &&
          errors.errors.description &&
          errors.errors.description.map((name, idx) => (
            <div className="error-message" key={idx}>
              {name.msg}
            </div>
          ))}
      </Form>
    </Modal>
  );
};

ModalCategoryForm.propTypes = {
  categoryData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    siteId: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
};

export default ModalCategoryForm;
