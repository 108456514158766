import { Form, Select, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
// import Table from 'components/Table';
import './ScreenConfiguration.css';
import { fetchConfig } from 'state/actions/config';
import { fetchSettings, modifysettings } from 'state/actions/settings';
import { fetchScreens } from 'state/actions/screens';

const ScreenConfiguration = ({ handleChangeTab }) => {
  const { siteId } = useParams();

  const [data, setData] = useState({});
  const [selectedScreen, setSelectedScreen] = useState('');

  const { configList, error, loading, settings, screenList } = useSelector(
    (state) => ({
      configList: state.config.list,
      settings: state.settings.settings,
      error: state.settings.error,
      loading: state.settings.loading,
      deleted: state.settings.deleted,
      updated: state.settings.updated,
      success: state.settings.success,
      screenList: state.screens.list,
    }),
    shallowEqual
  );

  if (configList) {
    log(configList, '<<<< information categories list');
  }

  if (settings) {
    log(settings, '<<<< settings');
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedScreen) {
      dispatch(fetchConfig({ siteId, screenId: selectedScreen }));
    }
  }, [siteId, selectedScreen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedScreen) {
      dispatch(fetchSettings({ siteId, screenId: selectedScreen }));
    }
  }, [siteId, selectedScreen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const settingsData = settings ? settings.settings : null;
    setData({
      ...settingsData,
    });
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSave = () => {
    dispatch(
      modifysettings({ siteId, settings: data, screenId: selectedScreen })
    );
  };

  const handleChangeScreen = (value) => {
    setSelectedScreen(value);
  };

  return (
    <section className="information-categories">
      {loading ? (
        <ClipLoader />
      ) : screenList.length > 0 ? (
        <div>
          <div style={{ width: 200, marginBottom: '2rem' }}>
            <Form layout="vertical">
              <Form.Item label="Select Screen">
                <Select
                  onChange={handleChangeScreen}
                  placeholder="Select Screen"
                  style={{ width: 200 }}
                >
                  {screenList.map((screen) => {
                    return (
                      <Select.Option key={screen._id} value={screen._id}>
                        {screen.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>

          {selectedScreen && (
            <>
              <div className="categories__table">
                {configList.length > 0 ? (
                  <div>
                    <h2 className="page-title">Screen Configuration</h2>
                    <Form layout="vertical">
                      {configList.map((config) => {
                        return (
                          <Form.Item label={config.variable} key={config._id}>
                            <Select
                              value={
                                data[config.variable] && data[config.variable]
                              }
                              onChange={(value) =>
                                handleChange(config.variable, value)
                              }
                              placeholder={`Select ${config.variable}`}
                            >
                              {config.values.map((value, index) => {
                                return (
                                  <Select.Option value={value} key={index}>
                                    {value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        );
                      })}
                      <button
                        onClick={handleSave}
                        className="button-primary mt-1"
                      >
                        Save Changes
                      </button>
                    </Form>
                  </div>
                ) : (
                  <p>
                    Configuration not exists yet, please add the configuration
                    variable first!
                  </p>
                )}
                {error && 'Show error'}
              </div>
            </>
          )}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>
              No Screen available, please create new one <br />{' '}
              <button
                className="button-primary mt-2"
                onClick={() => handleChangeTab('screens')}
              >
                <i className="feather-plus" />
                Add new screen
              </button>
            </span>
          }
        />
      )}
    </section>
  );
};

export default ScreenConfiguration;
