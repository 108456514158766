import { Button, Modal, Table, Tooltip } from 'antd';
import ModalFloorForm from 'components/ModalFloorForm';
import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  createFloor,
  deleteFloor,
  fetchFloor,
  modifyFloor,
} from 'state/actions/floor';
import { regexInput } from 'utils/regex';
import './Floors.scss';

export default function Floors() {
  const { siteId } = useParams();

  const {
    floorList,
    error,
    loading,
    deleted,
    floorData,
    userData,
    updated,
    role,
  } = useSelector(
    (state) => ({
      floorList: state.floor.list,
      error: state.floor.error,
      loading: state.floor.loading,
      deleted: state.floor.deleted,
      floorData: state.floor.floor,
      userData: state.auth.userData,
      updated: state.floor.updated,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [floor, setFloor] = useState(floorData || {});
  const [deleteModal, setDeleteModal] = useState({
    floorId: null,
    isOpen: false,
  });
  const [searchError, setSearchError] = useState(null);

  const dispatch = useDispatch();
  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchFloor({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updated && !loading) {
      dispatch(fetchFloor({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        floorId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchFloor({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemoveButtonClickHandler = (floorId) => {
    setDeleteModal((prevState) => ({
      floorId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onNewFloorHandler = () => {
    setFloor({});
    setIsEditing(false);
    setVisible(true);
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyFloor : createFloor;

    dispatch(
      action({
        uid,
        ...values,
        siteId,
      })
    );
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ floorId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteFloor(deleteModal.floorId));
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Option',
      dataIndex: 'option',
      render: (text, record) => (
        <div className="floor__actions">
          {role && (
            <div className="buttons is-right">
              <Tooltip title="Edit Floor">
                <button
                  data-tooltip="Edit"
                  className="btn btn--edit"
                  onClick={() => {
                    setFloor(record);
                    setVisible(true);
                    setIsEditing(true);
                  }}
                >
                  <i className="feather-edit" />
                </button>
              </Tooltip>

              <Tooltip title="Delete Floor">
                <button
                  type="button"
                  className="btn btn--delete"
                  data-tooltip="Delete"
                  onClick={() => onRemoveButtonClickHandler(record._id)}
                >
                  <i className="feather-trash-2" />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? floorList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.question.toLowerCase().includes(search.toLowerCase());
      })
    : floorList;

  const confirmMessage = useFormatMessage('Floor.confirm');

  const permDeleteMessage = useFormatMessage('Floor.permDelete');

  return (
    <section className="floor">
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        >
          {permDeleteMessage}
        </Modal>
      )}

      {visible && (
        <ModalFloorForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          floor={floor}
          setFloor={setFloor}
          isEditing={isEditing}
        />
      )}
      <div className="floor__nav">
        <div className="floor__search-wrapper">
          <div className="floor__search">
            <i className="feather-search"></i>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>

        <Button
          type="primary"
          className="button-primary"
          onClick={onNewFloorHandler}
        >
          {useFormatMessage('Floor.newFloor')}
        </Button>
      </div>

      <div className="floor__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="_id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
