import {
  Button,
  Modal,
  Table,
  Tooltip,
  Form,
  Input,
  Empty,
  Select,
} from 'antd';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
// import Table from 'components/Table';
import './ConfigVariable.css';
import {
  createconfig,
  deleteconfig,
  fetchConfig,
  modifyconfig,
} from 'state/actions/config';
import _ from 'underscore';
import { fetchScreens } from 'state/actions/screens';
import { regexInput } from 'utils/regex';

const ConfigVariable = ({ handleChangeTab }) => {
  const { siteId } = useParams();

  const {
    configList,
    error,
    loading,
    deleted,
    updated,
    role,
    success,
    screenList,
  } = useSelector(
    (state) => ({
      configList: state.config.list,
      error: state.config.error,
      loading: state.config.loading,
      deleted: state.config.deleted,
      updated: state.config.updated,
      role: state.auth.userData.role,
      success: state.config.success,
      screenList: state.screens.list,
    }),
    shallowEqual
  );

  if (configList) {
    log(configList, '<<<< information categories list');
  }

  const [deleteModal, setDeleteModal] = useState({
    categoryId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataValues, setDataValues] = useState([]);
  const [data, setData] = useState({
    variable: '',
    id: '',
  });

  const [selectedScreen, setSelectedScreen] = useState('');
  const [errors, setErrors] = useState(error);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrors(error);
  }, [error]);

  useEffect(() => {
    if (selectedScreen) {
      dispatch(fetchConfig({ siteId, screenId: selectedScreen }));
    }
  }, [siteId, selectedScreen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVisible(false);
    setDataValues([]);
    setData({
      variable: '',
    });
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchConfig({ siteId, screenId: selectedScreen }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted) {
      setDeleteModal({
        ...deleteModal,
        isOpen: false,
      });
    }
  }, [deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = !role && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (categoryId) => {
    setDeleteModal((prevState) => ({
      categoryId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ categoryId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteconfig(deleteModal.categoryId));
  };

  const onNewCategoryHandler = () => {
    setIsEditing(false);
    setVisible(true);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeRow = (id, value) => {
    const newDataValues = [...dataValues];
    const findDataValues = newDataValues.find((x) => x.id === id);
    if (findDataValues) {
      findDataValues.value = value;
      setDataValues(newDataValues);
    }
  };

  const handleDeleteRow = (id) => {
    const filterDataValues = dataValues.filter((x) => x.id !== id);
    setDataValues(filterDataValues);
  };

  const handleAddRow = () => {
    const id = Math.random();
    setDataValues([
      ...dataValues,
      {
        id,
        value: '',
      },
    ]);
    log(dataValues);
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  console.log(errors);

  const handleSave = () => {
    if (!regexInput(data.variable) && data.variable) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.variable = !regexInput(data.variable) &&
        data.variable && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      setErrors(newErrors);
    } else {
      setErrors({
        errors: null,
        message: '',
      });
      dispatch(
        isEditing
          ? modifyconfig({
              ...data,
              values: _.pluck(dataValues, 'value'),
              siteId,
              screenId: selectedScreen,
            })
          : createconfig({
              ...data,
              values: _.pluck(dataValues, 'value'),
              siteId,
              screenId: selectedScreen,
            })
      );
    }
  };

  const columns = [
    {
      title: 'Variable',
      dataIndex: 'variable',
    },
    {
      title: 'Values',
      dataIndex: 'values',
      render: (values) => {
        return values && values.length > 0 ? values.join(', ') : '';
      },
    },
    {
      title: 'Option',
      dataIndex: 'option',

      render: (text, record) => (
        <div className="categories__actions">
          <div className="buttons is-right">
            <Tooltip title="Edit Variable">
              <button
                data-tooltip="Edit"
                onClick={() => {
                  setDataValues(
                    record.values.map((x) => {
                      log(x);
                      return {
                        id: Math.random(),
                        value: x,
                      };
                    })
                  );
                  setData({
                    variable: record.variable,
                    id: record._id,
                  });
                  setVisible(true);
                  setIsEditing(true);
                }}
                className="btn btn--edit"
              >
                <i className="feather-edit" />
              </button>
            </Tooltip>

            <Tooltip title="Delete Variable">
              <button
                data-tooltip="Delete"
                type="button"
                className="btn btn--delete"
                onClick={() => onRemoveButtonClickHandler(record._id)}
              >
                <i className="feather-trash-2" />
              </button>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  const handleChangeScreen = (value) => {
    setSelectedScreen(value);
  };

  const configVariables = search
    ? configList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.variable.toLowerCase().includes(search.toLowerCase());
      })
    : configList;

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Categories.permDelete');

  return (
    <section className="information-categories">
      {loading ? (
        <ClipLoader />
      ) : screenList.length > 0 ? (
        <div>
          <div style={{ width: 200, marginBottom: '2rem' }}>
            <Form layout="vertical">
              <Form.Item label="Select Screen">
                <Select
                  onChange={handleChangeScreen}
                  placeholder="Select Screen"
                  style={{ width: 200 }}
                >
                  {screenList.map((screen) => {
                    return (
                      <Select.Option key={screen._id} value={screen._id}>
                        {screen.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>

          {selectedScreen && (
            <>
              {redirect}
              {deleteModal.isOpen && (
                <Modal
                  title={confirmMessage}
                  visible={deleteModal.isOpen}
                  onOk={onDeleteUserHandler}
                  onCancel={onCloseModalHandler}
                >
                  {permDeleteMessage}
                </Modal>
              )}

              <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                okText="Submit"
                onOk={handleSave}
                okButtonProps={{
                  loading,
                }}
              >
                <Form layout="vertical">
                  <Form.Item
                    label="Variable"
                    validateStatus={
                      errors.errors && errors.errors.variable
                        ? 'error'
                        : 'success'
                    }
                    help={
                      errors.errors && errors.errors.variable
                        ? errors.errors.variable[0].msg
                        : ''
                    }
                  >
                    <Input
                      placeholder="Variable"
                      value={data.variable}
                      onChange={(e) => handleChange('variable', e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Value">
                    <div className="justify-left">
                      <button className="button-primary" onClick={handleAddRow}>
                        <i className="feather-plus mr-5" />
                        Add Row
                      </button>
                    </div>
                    <div>
                      <Table
                        rowKey={(data) => data.id}
                        columns={[
                          {
                            title: '',
                            dataIndex: 'value',
                            render: (value, data) => {
                              log(value, data);
                              return (
                                <Input
                                  value={value}
                                  onChange={(e) =>
                                    handleChangeRow(data.id, e.target.value)
                                  }
                                  placeholder="Value"
                                />
                              );
                            },
                          },
                          {
                            title: '',
                            key: 'option',
                            dataIndex: 'id',
                            render: (id) => {
                              return (
                                <button
                                  className="button-delete"
                                  onClick={() => handleDeleteRow(id)}
                                >
                                  <i className="feather-trash-2" />
                                </button>
                              );
                            },
                          },
                        ]}
                        pagination={false}
                        dataSource={dataValues}
                      />
                    </div>
                  </Form.Item>
                </Form>
              </Modal>

              <div className="categories__nav">
                <div className="categories__search-wrapper">
                  <div className="categories__search">
                    <i className="feather-search"></i>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  {searchError && (
                    <div className="search-error">{searchError}</div>
                  )}
                </div>

                <Button
                  type="primary"
                  className="button-primary"
                  onClick={onNewCategoryHandler}
                >
                  New Variable
                </Button>
              </div>

              <div className="categories__table">
                <Table
                  columns={columns}
                  dataSource={configVariables}
                  rowKey={(data) => data._id}
                />
                {error && 'Show error'}
              </div>
            </>
          )}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>
              No Screen available, please create new one <br />{' '}
              <button
                className="button-primary mt-2"
                onClick={() => handleChangeTab('screens')}
              >
                <i className="feather-plus" />
                Add new screen
              </button>
            </span>
          }
        />
      )}
    </section>
  );
};

export default ConfigVariable;
