import { Breadcrumb, Table, Tooltip } from 'antd';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchRoles } from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import { regexInput } from 'utils/regex';
// import './User.css';
import './Users.scss';

function Panel() {
  const [search, setSearch] = useState('');

  const {
    roleList,
    userList,
    // isAdmin,
    error,
    loading,
    deleted,
    role,
  } = useSelector(
    (state) => ({
      roleList: state.roles.list,
      userList: state.users.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.roles.error,
      loading: state.roles.loading,
      deleted: state.roles.deleted,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchUsers());
    }
  }, [dispatch, role]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(fetchRoles());
  }, [deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchError, setSearchError] = useState(null);

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Users with The Role',
      dataIndex: 'access',
      render: (text, record) => {
        return (
          <>
            {
              userList.filter(
                (user) => user.roleId && user.roleId === record._id
              ).length
            }
          </>
        );
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',
      render: (text, record) => {
        return (
          <div className="users__actions" key={record._id}>
            {(role === 'admin' && record.role === 'user') ||
            role === 'superadmin' ? (
              <Tooltip title="Edit Role">
                <Link to={`/panel/${record._id}`} className="btn btn--edit">
                  <i className="feather-edit" />
                  Edit Authentication Policy
                </Link>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
  ];

  const data = search
    ? roleList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.name &&
          clonedElem.name.toLowerCase().includes(search.toLowerCase());
        if (name) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      roleList;

  return (
    <section className="panel">
      {redirect}
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Admin Panel
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Admin Panel Roles</h1>
        </div>
        <div className="header-right-content">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search"></i>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {searchError && <div className="search-error">{searchError}</div>}
          </div>
        </div>
      </div>

      <div className="panel__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="_id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default Panel;
