import {
  Empty,
  message,
  Modal,
  Progress,
  Select,
  Tooltip,
  Upload,
  Collapse,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchCategories } from 'state/actions/categories';
import { fetchFloor } from 'state/actions/floor';
// import { fetchMaps } from 'state/actions/maps';
import { deletePoi, downloadPois, fetchPois } from 'state/actions/pois';
import _ from 'underscore';
import { log } from 'utils';
import { regexInput } from 'utils/regex';
import { url, imgUrl } from 'utils/url';
import defaultLogo from '../../assets/default-logo.png';
import './Pois.css';

const { Option } = Select;
const { Panel } = Collapse;

function Pois() {
  const [search, setSearch] = useState();
  const [floor, setFloor] = useState();
  const [modalUpload, setModalUpload] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchError, setSearchError] = useState(null);
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(16);

  const { siteId, siteName } = useParams();

  const {
    poisList,
    loading,
    deleted,
    // categoriesList,
    downloading,
    floorList,
  } = useSelector(
    (state) => ({
      poisList: state.pois.list,
      loading: state.pois.loading,
      downloading: state.pois.downloading,
      deleted: state.pois.deleted,
      // categoriesList: state.categories.list,
      floorList: state.floor.list,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    poiId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPois({ siteId }));
    dispatch(fetchCategories({ siteId }));
    dispatch(fetchFloor({ siteId }));
    // dispatch(fetchMaps({ siteId: siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (poisList) {
    log(poisList, '<<<< pois list');
  }

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        poiId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchPois({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = () => {
    dispatch(downloadPois({ siteId, siteName }));
  };

  const onRemoveButtonClickHandler = (id) => {
    setDeleteModal((prevState) => ({
      poiId: id,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ poiId: null, isOpen: false });
  };

  const onDeletePoiHandler = () => {
    dispatch(deletePoi(deleteModal.poiId, siteId));
  };

  const handleUpload = () => {
    setModalUpload(true);
  };

  const onFileChangedHandler = (file) => {
    setIsDragged(false);
    if (file.type !== 'text/csv') {
      message.error('File format must be csv!');
    } else {
      log(file);
      setIsUploaded(true);

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      };
      let data = new FormData();
      data.append('file', file);
      data.set('siteId', siteId);

      axios
        .post(`${url}/poi/import`, data, config)
        .then((res) => {
          message.success(res.data.message);
          setModalUpload(false);
          dispatch(fetchPois({ siteId }));
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error('Server is stopped');
          }
        })
        .finally(() => {
          setIsUploaded(false);
        });
    }
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const dataByFloor = floor
    ? // eslint-disable-next-line
      poisList.filter((el) => {
        if (el.floorId === floor) {
          return el;
        } else if (floor === 'All') {
          return poisList;
        }
      })
    : poisList;

  const filteredData = search
    ? poisList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : dataByFloor;

  const trimString = (string, length) => {
    if (string) {
      return string.length > length
        ? `${string.substring(0, length)}...`
        : string;
    }
  };

  return (
    <div className="pois">
      <div className="pois-header">
        <div className="pois-dropdown">
          <span>Filter by: </span>
          <Select
            defaultValue="All"
            onChange={(id) => setFloor(id)}
            style={{ minWidth: 300 }}
          >
            <Option value="All">All</Option>
            {floorList &&
              floorList.map((floor) => (
                <Option key={floor._id} value={floor._id}>
                  {floor.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className="search-bar-wrapper">
          <div className="search-bar-wrapper-wrapper">
            <div className="search-bar">
              <i className="feather-search" />
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {searchError && <div className="search-error">{searchError}</div>}
          </div>

          <Link
            to={`/${siteId}/information/${siteName}/pois/new`}
            className="button-primary"
          >
            New Poi
          </Link>
        </div>
      </div>
      <div className="mb-2">
        {downloading ? (
          <button className="button-secondary button-loading mr-1">
            <i className="mdi mdi-spin mdi-loading" />
            Loading...
          </button>
        ) : (
          <button className="button-secondary mr-1" onClick={handleDownload}>
            <i className="feather-download" />
            Download CSV
          </button>
        )}
        <button className="button-secondary" onClick={handleUpload}>
          <i className="feather-upload" />
          Upload CSV
        </button>
      </div>

      {deleteModal.isOpen && (
        <Modal
          title="
          Are you sure want to delete this data?
          "
          visible={deleteModal.isOpen}
          onOk={onDeletePoiHandler}
          onCancel={onCloseModalHandler}
        >
          Data cannot be restored after delete
        </Modal>
      )}

      <Modal
        footer={false}
        onCancel={() => {
          setModalUpload(false);
          setIsUploaded(false);
        }}
        visible={modalUpload}
        title="Import POI"
        className="modal-poi-upload"
      >
        {isUploaded ? (
          <div className="upload-loading">
            <p>
              <i className="mdi mdi-loading mdi-spin" />
              Uploading...
            </p>
            <Progress percent={progress} />
          </div>
        ) : (
          <Upload
            showUploadList={false}
            beforeUpload={onFileChangedHandler}
            style={{ width: '100%' }}
          >
            <div
              onDragLeave={() => setIsDragged(false)}
              onDragOver={() => setIsDragged(true)}
              className={`modal-drop ${isDragged ? 'active' : ''}`}
            >
              <p>Drag and drop file here</p>
              <span>Or</span>
              <button className="button-primary">
                <i className="feather-file" />
                Browse File
              </button>
            </div>
          </Upload>
        )}
      </Modal>

      {poisList.length > 0 ? (
        filteredData && (
          <Collapse>
            {Array.from(
              Object.keys(_.groupBy(filteredData, 'categoryName'))
            ).map((categoryName, index) => {
              const groupedPoi = _.groupBy(filteredData, 'categoryName');
              return (
                <Panel
                  key={index}
                  header={categoryName}
                  className="pois-accordion__header"
                >
                  <div className="pois-content">
                    {groupedPoi[categoryName].map((poi) => {
                      return (
                        <div className={`pois-card`} key={poi._id}>
                          <div className="poi-data">
                            <img
                              src={
                                poi.logo
                                  ? `${imgUrl}/poi/${poi.logo}`
                                  : defaultLogo
                              }
                              alt="poi-logo"
                            />
                            <div className="poi-details">
                              <div className="poi-title-wrapper">
                                <h3>{trimString(poi.name, 20)}</h3>
                              </div>
                              <p>{trimString(poi.categoryName, 20)}</p>
                              <p>
                                <i className="feather-map-pin" /> {poi.location}
                              </p>
                            </div>
                          </div>
                          <div className="poi-actions">
                            <Tooltip title="Delete">
                              <button
                                onClick={() =>
                                  onRemoveButtonClickHandler(poi._id)
                                }
                              >
                                <i className="feather-trash-2" />
                              </button>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <Link
                                to={`/${siteId}/information/${siteName}/pois/${poi._id}`}
                              >
                                <i className="feather-edit" />
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        )
      ) : (
        <div className="empty-pois-state">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No POI available, please create new one</span>}
          />
        </div>
      )}
    </div>
  );
}

export default Pois;
