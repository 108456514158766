import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
import FeedbackForm from 'components/FeedbackForm';
import {
  createFeedback,
  modifyFeedback,
  fetchFeedback,
} from 'state/actions/feedback';
import { useFormatMessage } from 'hooks';

const Feedback = ({ history }) => {
  const { id } = useParams();

  const { success, feedbackList, feedbackData, sitesList, error } = useSelector(
    (state) => ({
      success: state.feedback.success,
      feedbackList: state.feedback.list,
      feedbackData: state.feedback.feedback,
      sitesList: state.sites.list,
      error: state.feedback.error,
    }),
    shallowEqual
  );

  log(feedbackData);

  const [feedback, setFeedback] = useState(feedbackData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const feedbackFetched = feedbackList.find(
        (fetchedFeedback) => fetchedFeedback._id === id
      );
      if (feedbackFetched) {
        setFeedback(feedbackFetched);
      } else if (feedbackData.id === id) {
        setFeedback(feedbackData);
      } else {
        dispatch(fetchFeedback(id));
      }
    }
  }, [id, feedbackData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      const feedbackFetched = feedbackList.find(
        (fetchedFeedback) => fetchedFeedback.id === id
      );
      if (feedbackFetched) {
        setFeedback(feedbackFetched);
      } else if (feedbackData.id === id) {
        dispatch(fetchFeedback({ feedbackId: id }));
      }
    }
  }, [id, feedbackData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEditing = !!id;

  const feedbackForm =
    !feedback && id ? (
      <ClipLoader />
    ) : (
      <FeedbackForm
        isEditing={isEditing}
        feedback={feedback}
        sites={sitesList}
        setFeedback={setFeedback}
        action={isEditing ? modifyFeedback : createFeedback}
        success={success}
        history={history}
        id={id}
        errors={error && error.errors}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editFeedbackMessage = useFormatMessage('Feedback.editFeedback');

  const newFeedbackMessage = useFormatMessage('Feedback.newFeedback');

  return (
    <>
      {/* {redirect} */}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editFeedbackMessage : newFeedbackMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">{feedbackForm}</section>
    </>
  );
};

export default Feedback;
