/* eslint-disable jsx-a11y/label-has-associated-control */
import { useChangeHandler, useFormatMessage } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { mapsCleanUp } from 'state/actions/maps';
import {imgUrl} from 'utils/url';
import './MapForm.scss';

const MapForm = ({ map, setMap, action, history, success, id, error }) => {
  const { siteId, siteName } = useParams();

  const { loading, userData } = useSelector(
    (state) => ({
      loading: state.maps.loading,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(mapsCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setMap);

  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setMap((prevState) => ({ ...prevState, file, logoUrl: null }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(action({ uid: uid, ...map, siteId: siteId, id }));
  };

  const imagePreviewUrl = map.file
    ? map.file && URL.createObjectURL(map.file)
    : map.fileUrl && `${imgUrl}/map/${map.fileUrl}`;

  const goBackMessage = useFormatMessage('MapForm.goBack');

  const pickAnotherFileMessage = useFormatMessage('MapForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('MapForm.pickFile');

  const handleReset = () => {
    setMap({
      ...map,
      description: '',
      fileUrl: null,
      file: null,
      name: '',
    });
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('MapForm.mapInfo')}
              </p>
            </header>
            <div className="card-content">
              <form>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('MapForm.name')}{' '}
                      <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="name"
                          className={`input ${
                            error && error.errors && error.errors.name
                              ? 'is-danger'
                              : ''
                          }`}
                          type="text"
                          required
                          name="name"
                          value={map.name}
                          onChange={onChangeHandler}
                        />
                      </div>
                      {error && error.errors && error.errors.name && (
                        <p className="help is-danger">
                          {error.errors.name[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('MapForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          style={{
                            height: 100,
                          }}
                          id="description"
                          className="input"
                          type="text"
                          required
                          name="description"
                          value={map.description}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('MapForm.image')}{' '}
                      <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div
                        className={`file has-name ${
                          error && error.errors && error.errors.file
                            ? 'is-danger'
                            : ''
                        }`}
                      >
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            accept="image/*"
                            onChange={onFileChangedHandler}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="fas fa-upload" />
                            </span>
                            <span className="file-label">
                              {map.file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {map.file && map.file.name}
                          </span>
                        </label>
                      </div>
                      {error && error.errors && error.errors.file && (
                        <p className="help is-danger">
                          {error.errors.file[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            onClick={onSubmitHandler}
                            type="button"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                            // disabled={!canSubmit}
                          >
                            <span>{useFormatMessage('MapForm.submit')}</span>
                          </button>
                        </div>
                        <div className="control">
                          <Link
                            to={{
                              pathname: `/${siteId}/information/${siteName}`,
                              id: 'map',
                            }}
                            className="button"
                          >
                            {goBackMessage}
                          </Link>
                        </div>
                        <button
                          type="reset"
                          className="button"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('MapForm.mapPreview')}
              </p>
            </header>
            <div className="card-content">
              {imagePreviewUrl && (
                <>
                  <div className="is-map-avatar image has-max-width is-aligned-center">
                    <img
                      className="map-avatar"
                      src={imagePreviewUrl}
                      alt="User profile logo preview"
                    />
                  </div>
                  <hr />
                </>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('MapForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={map.name}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('MapForm.description')}
                </label>
                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={map.description}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MapForm.propTypes = {
  mapData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    fileUrl: PropTypes.string,
    siteId: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default MapForm;
