import { Breadcrumb, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchRespondent } from 'state/actions/respondent';
import './Respondent.css';
import './Respondent.scss';

const Respondent = ({ history }) => {
  const { id } = useParams();
  const { siteId, siteName } = useParams();

  const { respondentData } = useSelector(
    (state) => ({
      respondentData: state.respondent.respondent,
    }),
    shallowEqual
  );

  const respondent = useState(respondentData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRespondent({ id }));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const respondentForm =
    !respondent && id ? (
      <ClipLoader />
    ) : (
      <>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div
                  style={{
                    width: '99%',
                    display: 'inline-block',
                  }}
                >
                  <Line
                    data={{
                      labels: respondentData.labels,
                      datasets: [
                        {
                          label: '1 Star',
                          fill: false,
                          data:
                            respondentData && respondentData.dataSet
                              ? respondentData.dataSet.star1
                              : [],
                          borderColor: '#e67e22',
                        },
                        {
                          label: '2 Star',
                          fill: false,
                          data:
                            respondentData && respondentData.dataSet
                              ? respondentData.dataSet.star2
                              : [],
                          borderColor: '#f1c40f',
                        },
                        {
                          label: '3 Star',
                          fill: false,
                          data:
                            respondentData && respondentData.dataSet
                              ? respondentData.dataSet.star3
                              : [],
                          borderColor: '#2ecc71',
                        },
                        {
                          label: '4 Star',
                          fill: false,
                          data:
                            respondentData && respondentData.dataSet
                              ? respondentData.dataSet.star4
                              : [],
                          borderColor: '#3498db',
                        },
                        {
                          label: '5 Star',
                          fill: false,
                          data:
                            respondentData && respondentData.dataSet
                              ? respondentData.dataSet.star5
                              : [],
                          borderColor: '#34495e',
                        },
                      ],
                    }}
                    options={{
                      bezierCurve: false,
                      scales: {
                        xAxes: [
                          {
                            scaleLabel: {
                              display: true,
                              labelString: 'Time',
                            },
                          },
                        ],
                        yAxes: [
                          {
                            scaleLabel: {
                              display: true,
                              labelString: 'Answer',
                            },
                            ticks: {
                              beginAtZero: false,
                              userCallback: function (label, index, labels) {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                  return label;
                                }
                              },
                            },
                          },
                        ],
                      },
                    }}
                  />
                </div>
                <div>
                  <div className="footer-chart">
                    <p>Total Respondend</p>
                    <p>: {respondentData.total}</p>
                  </div>
                  <div className="footer-chart">
                    <p>Avg Score</p>
                    <p>: {respondentData.average}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <section className="respondent">
      <h1 className="respondent__title">Respondent</h1>
      <hr className="respondent__line" />
      <div className="respondent__header">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link
              to={`/${siteId}/information/${siteName}`}
              className="profile__breadcrumb"
            >
              Feedback
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="profile__breadcrumb profile__breadcrumb--active">
            Respondent
          </Breadcrumb.Item>
        </Breadcrumb>
        <Link to={`/${siteId}/information/${siteName}`}>
          <Button type="primary" className="button">
            Back
          </Button>
        </Link>
      </div>
      <div>{respondentForm}</div>
    </section>
  );
};

export default Respondent;
