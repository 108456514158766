import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { errorMsg } from 'utils';

export const API_FETCH_DATA_INIT = createAction('API_FETCH_DATA_INIT');
export const API_FETCH_DATA_LOADING = createAction('API_FETCH_DATA_LOADING');
export const API_FETCH_DATA_SUCCESS = createAction('API_FETCH_DATA_SUCCESS');
export const API_FETCH_DATA_FAIL = createAction('API_FETCH_DATA_FAIL');
export const API_SUBMIT_DATA_INIT = createAction('API_SUBMIT_DATA_INIT');
export const API_SUBMIT_DATA_LOADING = createAction('API_SUBMIT_DATA_LOADING');
export const API_SUBMIT_DATA_SUCCESS = createAction('API_SUBMIT_DATA_SUCCESS');
export const API_SUBMIT_DATA_FAIL = createAction('API_SUBMIT_DATA_FAIL');
export const API_DELETE_DATA_INIT = createAction('API_DELETE_DATA_INIT');
export const API_DELETE_DATA_FAIL = createAction('API_DELETE_DATA_FAIL');
export const API_DELETE_DATA_SUCCESS = createAction('API_DELETE_DATA_SUCCESS');
export const API_MODIFY_DATA_INIT = createAction('API_MODIFY_DATA_INIT');
export const API_MODIFY_DATA_LOADING = createAction('API_MODIFY_DATA_LOADING');
export const API_MODIFY_DATA_SUCCESS = createAction('API_MODIFY_DATA_SUCCESS');
export const API_MODIFY_DATA_FAIL = createAction('API_MODIFY_DATA_FAIL');
export const API_CLEAN_UP = createAction('API_CLEAN_UP');

export const fetchInstagram = ({ tags, siteId, limit }) => {
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_INIT());
    if (tags.length > 0) {
      let api;
      try {
        api = await Axios.post(`${url}/api/instagram`,{
          hashtag: tags,
          siteId: siteId,
          limit: limit,
        }, {
          
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(API_FETCH_DATA_FAIL({ error }));
      }

      api = api.data.data;

      return dispatch(
        API_FETCH_DATA_SUCCESS({
          list: api,
        })
      );
    }
  };
};
export const fetchInstagramUsername = ({ tags, siteId, limit }) => {
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_INIT());
    if (tags.length > 0) {
      let api;
      try {
        api = await Axios.post(`${url}/api/instagram-username`,{
          usernames: tags,
          siteId: siteId,
          limit: limit,
        }, {
          
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(API_FETCH_DATA_FAIL({ error }));
      }

      api = api.data.data;

      return dispatch(
        API_FETCH_DATA_SUCCESS({
          list: api,
        })
      );
    }
  };
};
export const fetchArrivalInit = () => {
  console.log('fetch arrival init func');
  return async (dispatch, getState) => {
    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: [],
      })
    );
  };
};
export const submitAddImage = ({ media, siteId, type }) => {
  return async (dispatch, getState) => {
    dispatch(API_SUBMIT_DATA_INIT());
    console.log( siteId, type, localStorage.getItem('accessToken'));
    const fd = new FormData();
    fd.set('name', media.name);
    fd.set('description', media.description);
    type && fd.set('type', type);
    media.video_url && fd.set('video_url', media.video_url);
    fd.set('siteId', siteId);
    fd.append('file', media.file);
    Axios.post(`${url}/playlistMedia/upload`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        toastr.success('', 'Playlist is updated successfully');

        // message.success(res.data.message);
        // apiList.push(res.data.data);
      })
      .catch((error) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        console.log(error);
        toastr.error('', errorMessage);
        return dispatch(
          API_SUBMIT_DATA_FAIL({
            error: error.response,
          })
        );
      })
      .finally(() => {
        // dispatch(fetchArrival({siteId}));
        return dispatch(API_SUBMIT_DATA_SUCCESS());
      });
  };
};

export const editImage = ({ media, siteId }) => {
  return async (dispatch) => {
    dispatch(API_MODIFY_DATA_INIT());

    const fd = new FormData();

    fd.set('id', media.id);
    fd.set('name', media.name);
    fd.set('description', media.description);
    fd.set('siteId', siteId);
    fd.append('file', media.file);
    Axios.post(`${url}/playlistMedia/edit`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        toastr.success('', 'Playlist is updated successfully');
        return dispatch(API_MODIFY_DATA_SUCCESS());
      })
      .catch((error) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);

        return dispatch(
          API_MODIFY_DATA_FAIL({
            error: error && error.response && error.response.data,
          })
        );
      });
  };
};

export const fetchArrival = ({ siteId }) => {
  console.log('fetch arrival fetch func');
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_LOADING());
    const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
    const serverToken =
      'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
    var headers = {
      Authorization: `${serverToken}`,
      // 'Auth-Token': `${token}`,
    };
    const login = {
      input_body: {
        username: 'dev@adactive.asia',
        password: 'Password 123@',
      },
    };
    let api;
    let api1;
    // let api2;
    let api3;
    try {
      const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
        headers,
      });
      const token = dataLogin.data.data.auth_token;
      headers['Auth-Token'] = token;
      const body = {
        input_body: {
          search_value: '',
          page: 1,
          page_size: 1000,
          only_pinned: false,
          pinned_first: false,
        },
      };
      // const body2 = {
      //   input_body: {
      //     search_value: '',
      //     page: 1,
      //     page_size: 1000,
      //     category: ['Exhibitions & Festivals'],
      //   },
      // };
      api1 = await Axios.post(
        `${url_ngs}/gallery_hq/coi/search/artwork`,
        body,
        {
          headers,
        }
      );
      // api2 = await Axios.post(
      //   `${url_ngs}/gallery_hq/coi/search/product`,
      //   body2,
      //   { headers }
      // );
      api3 = await Axios.get(`${url}/playlistMedia/get`, {
        params: {
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(API_FETCH_DATA_FAIL({ error }));
    }

    api1 = api1.data.data.search_result.map((x) => {
      return {
        ...x,
        type: 'Artwork',
      };
    });
    // api2 = api2.data.data.search_result.map((x) => {
    //   return {
    //     ...x,
    //     type: 'Product',
    //   };
    // });
    api3 = api3.data.data.map((x) => {
      if (x.ext === 'video/mp4') {
        return {
          ...x,
          video_url: x.url,
          artist: x.name2,
          type: 'Manual Upload',
        };
      }
      return {
        ...x,
        image_url: x.url,
        artist: x.name2,
        type: 'Manual Upload',
      };
    });
    // api = [...api1, ...api2, ...api3];
    api = [...api1,...api3];
    api.map((a, index) => {
      var img = new Image();

      img.onload = function () {
        var height = img.height;
        var width = img.width;
        height > width
          ? (api[index].orientation = 'portrait')
          : (api[index].orientation = 'landscape');

        // setDimensions([...dimensions,dimension]);
        // code here to use the dimensions
      };

      img.src = a.image_url;
      if (api[index].additional_detail) {
        const d = api[index];
        api[index].description = `${d.artist}. c. ${
          d.additional_detail.dating
        }. ${d.additional_detail.medium}, ${
          d.additional_detail.dimensions[0] &&
          d.additional_detail.dimensions[0].data
        }. ${d.additional_detail.creditline}`;
      } 

      return null;
    });

    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: api,
      })
    );
  };
};
export const fetchActivity = ({ siteId }) => {
  console.log('fetch activity init func');
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_LOADING());
    const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
    const serverToken =
      'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
    var headers = {
      Authorization: `${serverToken}`,
      // 'Auth-Token': `${token}`,
    };
    const login = {
      input_body: {
        username: 'dev@adactive.asia',
        password: 'Password 123@',
      },
    };
    let api;
    let api1;
    let api2;
    let api3;
    try {
      const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
        headers,
      });
      const token = dataLogin.data.data.auth_token;
      headers['Auth-Token'] = token;
      const body = {
        input_body: {
          search_value: '',
          page: 1,
          page_size: 1000,
          only_pinned: false,
          pinned_first: false,
        },
      };
      const body2 = {
        input_body: {
          search_value: '',
          page: 1,
          page_size: 1000,
          category: ['Exhibitions'],
        },
      };
      const body3 = {
        input_body: {
          search_value: '',
          page: 1,
          page_size: 1000,
          category: ['Tours'],
        },
      };
      api1 = await Axios.post(
        `${url_ngs}/gallery_hq/coi/search/artwork`,
        body,
        {
          headers,
        }
      );
      api2 = await Axios.post(
        `${url_ngs}/gallery_hq/coi/search/product`,
        body2,
        { headers }
      );
      api3 = await Axios.post(
        `${url_ngs}/gallery_hq/coi/search/product`,
        body3,
        { headers }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(API_FETCH_DATA_FAIL({ error }));
    }

    api1 = api1.data.data.search_result.map((x) => {
      return {
        ...x,
        type: 'Artwork',
      };
    });
    api2 = api2.data.data.search_result.map((x) => {
      return {
        ...x,
        type: 'Exhibition',
      };
    });

    api3 = api3.data.data.search_result.map((x) => {
      return {
        ...x,
        type: 'Tours',
      };
    });
    api = [...api1, ...api2, ...api3];
    api.map((a, index) => {
      var img = new Image();

      img.onload = function () {
        var height = img.height;
        var width = img.width;
        height > width
          ? (api[index].orientation = 'portrait')
          : (api[index].orientation = 'landscape');

        // setDimensions([...dimensions,dimension]);
        // code here to use the dimensions
      };
      // console.log('get activity pillar')

      img.src = a.image_url;
      if (api[index].additional_detail) {
        const d = api[index];
        api[index].description_credit = `${d.artist}. c. ${
          d.additional_detail.dating
        }. ${d.additional_detail.medium}, ${
          d.additional_detail.dimensions[0] &&
          d.additional_detail.dimensions[0].data
        }. ${d.additional_detail.creditline}`;
      }
      return null;
    });

    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: api,
      })
    );
  };
};

export const fetchWayfinding = ({ siteId }) => {
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_LOADING());
    const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
    const serverToken =
      'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
    var headers = {
      Authorization: `${serverToken}`,
      // 'Auth-Token': `${token}`,
    };
    const login = {
      input_body: {
        username: 'dev@adactive.asia',
        password: 'Password 123@',
      },
    };
    let api;
    let api1;
    try {
      const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
        headers,
      });
      const token = dataLogin.data.data.auth_token;
      headers['Auth-Token'] = token;
      const body = {
        input_body: {
          search_value: '',
          page: 1,
          page_size: 1000,
          only_pinned: false,
          pinned_first: false,
        },
      };
      api1 = await Axios.post(
        `${url_ngs}/gallery_hq/coi/search/venue`,
        body,
        {
          headers,
        }
      );
     
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(API_FETCH_DATA_FAIL({ error }));
    }

    api = api1.data.data.search_result;
   
    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: api,
      })
    );
  };
};

export const fetchCuratedPlaylist = ({ siteId }) => {
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_LOADING());
    const url_ngs = 'https://apihub.nationalgallery.sg/_presentation';
    const serverToken =
      'PROD_eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZlbG9wZXJfaWQiOjI2LCJkaXNwbGF5X25hbWUiOiJBZGl0eWEiLCJ2ZW5kb3JfbmFtZSI6IkFEQUNUSVZFIiwiZW1haWwiOiJ0ZWNodGVhbUBhZGFjdGl2ZS5hc2lhIiwidG91Y2hwb2ludCI6IkFDVElWSVRZIFBJTExBUiIsIm1vZGUiOiJQUk9EIiwiaWF0IjoxNjM4MTc0MjgwfQ.FURJTiTs462okpstFfHR8ywMR7jUk7C9NUsxS2aNuJQ';
    var headers = {
      Authorization: `${serverToken}`,
      // 'Auth-Token': `${token}`,
    };
    const login = {
      input_body: {
        username: 'dev@adactive.asia',
        password: 'Password 123@',
      },
    };
    let api;
    let api1;
    try {
      const dataLogin = await Axios.post(`${url_ngs}/authentication`, login, {
        headers,
      });
      const token = dataLogin.data.data.auth_token;
      headers['Auth-Token'] = token;
      const body = {
        input_body: {
          search_value: '',
          page: 1,
          page_size: 200,
          only_pinned: false,
          pinned_first: false,
        },
      };
      api1 = await Axios.post(
        `${url_ngs}/gallery_hq/itinerary/curated_itineraries`,
        body,
        {
          headers,
        }
      );
     
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(API_FETCH_DATA_FAIL({ error }));
    }

    api = api1.data.data.search_result;
    console.log(api);
   
    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: api,
      })
    );
  };
};

export const deletePlaylist = (data) => {
  // console.log(data, '<<< data delete playlist');
  return async (dispatch, getState) => {
    dispatch(API_DELETE_DATA_INIT());
    try {
      await Axios.post(`${url}/playlistMedia/delete`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      console.log('try delete media');
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        API_DELETE_DATA_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The media was deleted.');
    return dispatch(API_DELETE_DATA_SUCCESS({ data }));
  };
};

export const fetchYoutube = ({ siteId, type }) => {
  console.log('fetch youtube fetch func');
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_LOADING());
    let api;
    try {
      api = await Axios.get(`${url}/playlistMedia/get`, {
        params: {
          siteId,
          type,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(API_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: api.data.data,
      })
    );
  };
};

export const fetchArrivalNew = ({ siteId, type }) => {
  console.log('fetch arrival fetch func');
  return async (dispatch, getState) => {
    dispatch(API_FETCH_DATA_LOADING());
    let api;
    try {
      api = await Axios.get(`${url}/playlistMedia/get`, {
        params: {
          siteId,
          type,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(API_FETCH_DATA_FAIL({ error }));
    }
    return dispatch(
      API_FETCH_DATA_SUCCESS({
        list: getState().api.list,
        artwork: api.data.data,
      })
    );
  };
};

export const apiCleanUp = () => (dispatch) => dispatch(API_CLEAN_UP());
