import { DownOutlined } from '@ant-design/icons';
import { Breadcrumb, Dropdown, Menu } from 'antd';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setActiveTab } from 'state/actions/tab';
import { log } from 'utils';
import './Home.css';

function Home({ history }) {
  const { organizationsList, sites, userData, role } = useSelector(
    (state) => ({
      organizationsList: state.organizations.list,
      sites: state.sites.list,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );
  const [activeOrg, setActiveOrg] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData.role !== 'superadmin') {
      const userAccess = userData.roleAccess
        ? userData.roleAccess.access
        : userData.access;
      console.log(userAccess, 'userAccess');
      const active = activeOrg ?? [];
      sites.map((site) => {
        const dataSite = userAccess.find((acc) => acc.siteId === site._id);
        if (dataSite) {
          if (
            dataSite.media ||
            dataSite.poi ||
            dataSite.information ||
            dataSite.maps ||
            dataSite.feedback
          ) {
            !active.includes(site.organizationId) &&
              active.push(site.organizationId);
            return null;
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
      setActiveOrg(active);
      // console.log(activeOrg);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goTo = (toWhere, siteId, siteName) => {
    log(siteId, siteName, '<<< GO TO');
    dispatch(setActiveTab({ tab: toWhere }));
    history.push(`/${siteId}/information/${siteName}`); // eslint-disable-line react-hooks/exhaustive-deps
    sessionStorage.setItem('activeMenu', 'site');
  };

  const resetPassword = (!userData.isPasswordChanged ||
    userData.isPasswordExpired) && <Redirect to={paths.RESET_PASSWORD} />;
  // console.log(userData);
  const redirect = !role && <Redirect to={paths.ROOT} />;
  const validateAccessMenu = (crud) => {
    console.log(crud, 'validate menu');
    if (typeof crud !== 'object') return crud;
    return Object.values(crud).includes(true);
  };

  const media = (site) => (
    <Menu
      onClick={(e) => {
        goTo(e.key, site._id, site.name);
      }}
    >
      <Menu.Item key="media">
        <div>
          <i className="feather-image" /> Media List
        </div>
      </Menu.Item>
      <Menu.Item key="mediaCategory">
        <div>
          <i className="feather-list" /> Media Categories
        </div>
      </Menu.Item>
    </Menu>
  );

  const poi = (site) => (
    <Menu
      onClick={(e) => {
        goTo(e.key, site._id, site.name);
      }}
    >
      <Menu.Item key="poi">
        <div>
          <i className="feather-box" /> Poi List
        </div>
      </Menu.Item>
      <Menu.Item key="poiCategory">
        <div>
          <i className="feather-list" /> Poi Categories
        </div>
      </Menu.Item>
    </Menu>
  );

  const information = (site) => (
    <Menu
      onClick={(e) => {
        goTo(e.key, site._id, site.name);
      }}
    >
      <Menu.Item key="information">
        <div>
          <i className="feather-box" /> Information List
        </div>
      </Menu.Item>
      <Menu.Item key="informationCategory">
        <div>
          <i className="feather-list" /> Information Categories
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="dashboard">
      {resetPassword}
      {redirect}
      <div className="breadcrumb-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-active">
            Dashboard
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <h1 className="page-title">Dashboard</h1>

      {userData.role === 'superadmin'
        ? // eslint-disable-next-line

          organizationsList.map((organization) => {
            return (
              <div className="dashboard-table" key={organization._id}>
                <h1 className="title">{organization.name}</h1>
                <div className="dashboard-row-wrapper">
                  {sites &&
                    sites
                      .filter(
                        (site) => site.organizationId === organization._id
                      )
                      .map((site) => (
                        <div className="dashboard-row" key={site._id}>
                          <Link to={`/${site._id}/information/${site.name}`}>
                            <span className="dashboard-span">{site.name}</span>
                          </Link>
                          <ul className="service-list">
                            <li>
                              <Dropdown
                                overlay={() => media(site)}
                                placement="bottomCenter"
                              >
                                <div onClick={(e) => e.preventDefault()}>
                                  <i className="feather-image" /> Media{' '}
                                  <DownOutlined />
                                </div>
                              </Dropdown>
                            </li>
                            <li>
                              <Dropdown
                                overlay={() => poi(site)}
                                placement="bottomCenter"
                              >
                                <div onClick={(e) => e.preventDefault()}>
                                  <i className="feather-box" /> POI{' '}
                                  <DownOutlined />
                                </div>
                              </Dropdown>
                            </li>
                            <li>
                              <Dropdown
                                overlay={() => information(site)}
                                placement="bottomCenter"
                              >
                                <div onClick={(e) => e.preventDefault()}>
                                  <i className="feather-info" /> Information{' '}
                                  <DownOutlined />
                                </div>
                              </Dropdown>
                            </li>
                            <li
                              onClick={() => goTo('map', site._id, site.name)}
                            >
                              <i className="feather-map" />
                              Maps
                            </li>
                            <li
                              onClick={() =>
                                goTo('feedback', site._id, site.name)
                              }
                            >
                              <i className="feather-rss" /> Feedbacks
                            </li>
                          </ul>
                        </div>
                      ))}
                </div>
              </div>
            );
          })
        : // eslint-disable-next-line

          organizationsList.map((organization) => {
            const siteOrg = sites.filter(
              (site) => site.organizationId === organization._id
            );
            if (activeOrg) {
              // console.log(activeOrg)
              if (activeOrg.includes(organization._id)) {
                // console.log(organization._id)
                return (
                  <div className="dashboard-table" key={organization._id}>
                    <h1 className="title">{organization.name}</h1>
                    <div className="dashboard-row-wrapper">
                      {siteOrg.map((site) => {
                        const access = userData.roleAccess
                          ? userData.roleAccess.access.find(
                              (acc) => acc.siteId === site._id
                            )
                          : userData.access.find(
                              (acc) => acc.siteId === site._id
                            );
                        console.log(
                          userData.roleAccess,
                          site._id,
                          'access widget'
                        );
                        if (
                          access &&
                          (access.media ||
                            access.poi ||
                            access.information ||
                            access.feedback ||
                            access.maps)
                        ) {
                          return (
                            <div className="dashboard-row" key={site._id}>
                              <Link
                                to={`/${site._id}/information/${site.name}`}
                              >
                                <span className="dashboard-span">
                                  {site.name}
                                </span>
                              </Link>
                              <ul className="service-list">
                                {validateAccessMenu(access.media) && (
                                  <li>
                                    <Dropdown
                                      overlay={() => media(site)}
                                      placement="bottomCenter"
                                    >
                                      <div onClick={(e) => e.preventDefault()}>
                                        <i className="feather-image" /> Media{' '}
                                        <DownOutlined />
                                      </div>
                                    </Dropdown>
                                    {/* <Dropdown
                                  overlay={() => media(site)}
                                  placement="bottomCenter"
                                >
                                  <div onClick={(e) => e.preventDefault()}>
                                    <i className="feather-image" /> Media{' '}
                                    <DownOutlined />
                                  </div>
                                </Dropdown> */}
                                  </li>
                                )}

                                {validateAccessMenu(access.poi) && (
                                  <li>
                                    <Dropdown
                                      overlay={() => poi(site)}
                                      placement="bottomCenter"
                                    >
                                      <div onClick={(e) => e.preventDefault()}>
                                        <i className="feather-box" /> POI{' '}
                                        <DownOutlined />
                                      </div>
                                    </Dropdown>
                                  </li>
                                )}

                                {validateAccessMenu(access.information) && (
                                  <li>
                                    <Dropdown
                                      overlay={() => information(site)}
                                      placement="bottomCenter"
                                    >
                                      <div onClick={(e) => e.preventDefault()}>
                                        <i className="feather-info" />{' '}
                                        Information <DownOutlined />
                                      </div>
                                    </Dropdown>
                                  </li>
                                )}

                                {validateAccessMenu(access.map) && (
                                  <li
                                    onClick={() =>
                                      goTo('map', site._id, site.name)
                                    }
                                  >
                                    <i className="feather-map" />
                                    Maps
                                  </li>
                                )}

                                {validateAccessMenu(access.feedback) && (
                                  <li
                                    onClick={() =>
                                      goTo('feedback', site._id, site.name)
                                    }
                                  >
                                    <i className="feather-rss" /> Feedbacks
                                  </li>
                                )}
                              </ul>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            } else {
              return <></>;
            }
          })}
    </div>
  );
}

export default Home;
