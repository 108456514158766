import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import {url} from 'utils/url';
import { log } from 'utils';
import Axios from 'axios';

export const INFORMATION_FETCH_DATA_INIT = createAction(
  'INFORMATION_FETCH_DATA_INIT'
);
export const INFORMATION_FETCH_DATA_SUCCESS = createAction(
  'INFORMATION_FETCH_DATA_SUCCESS'
);
export const INFORMATION_FETCH_DATA_FAIL = createAction(
  'INFORMATION_FETCH_DATA_FAIL'
);

export const INFORMATION_DELETE_INIT = createAction('INFORMATION_DELETE_INIT');
export const INFORMATION_DELETE_SUCCESS = createAction(
  'INFORMATION_DELETE_SUCCESS'
);
export const INFORMATION_DELETE_FAIL = createAction('INFORMATION_DELETE_FAIL');

export const INFORMATION_CLEAR_DATA = createAction('INFORMATION_CLEAR_DATA');

export const INFORMATION_CREATE_INIT = createAction('INFORMATION_CREATE_INIT');
export const INFORMATION_CREATE_SUCCESS = createAction(
  'INFORMATION_CREATE_SUCCESS'
);
export const INFORMATION_CREATE_FAIL = createAction('INFORMATION_CREATE_FAIL');

export const INFORMATION_MODIFY_INIT = createAction('INFORMATION_MODIFY_INIT');
export const INFORMATION_MODIFY_SUCCESS = createAction(
  'INFORMATION_MODIFY_SUCCESS'
);
export const INFORMATION_MODIFY_FAIL = createAction('INFORMATION_MODIFY_FAIL');

export const INFORMATION_CLEAN_UP = createAction('INFORMATION_CLEAN_UP');

export const INFORMATION_CLEAR_DATA_LOGOUT = createAction(
  'INFORMATION_CLEAR_DATA_LOGOUT'
);

export const fetchInformation = ({ informationId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_FETCH_DATA_INIT());
    if (informationId) {
      let informationData;
      try {
        informationData = await Axios.get(`${url}/information/get`, {
          params: {
            id: informationId,
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(INFORMATION_FETCH_DATA_FAIL({ error }));
      }
      const information = { ...informationData.data.data, id: informationId };
      return dispatch(
        INFORMATION_FETCH_DATA_SUCCESS({
          list: getState().information.list,
          information,
        })
      );
    }

    if (siteId) {
      let information;
      try {
        information = await Axios.get(`${url}/information/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        log(information, '<<<< information');
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(INFORMATION_FETCH_DATA_FAIL({ error }));
      }
      return dispatch(
        INFORMATION_FETCH_DATA_SUCCESS({
          list: information.data.data,
          information: getState().information.information,
        })
      );
    }
  };
};

export const deleteInformation = ({id, siteId}) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_DELETE_INIT());

    try {
      await Axios.delete(`${url}/information/delete`, {
        params: {
          id,
          siteId
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      log(errorMessage);
      return dispatch(
        INFORMATION_DELETE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The information was deleted.');
    return dispatch(INFORMATION_DELETE_SUCCESS({ id }));
  };
};

export const clearInformationData = () => {
  return (dispatch) => {
    dispatch(INFORMATION_CLEAR_DATA());
  };
};

export const clearInformationDataLogout = () => {
  return (dispatch) => {
    dispatch(INFORMATION_CLEAR_DATA_LOGOUT());
  };
};

export const createInformation = ({
  name,
  description,
  siteId,
  categoryId,
  categoryName,
}) => {
  return async (dispatch, getState) => {
    let createInformation;
    try {
      createInformation = await Axios.post(
        `${url}/information/create`,
        {
          name,
          description,
          siteId,
          categoryId,
          categoryName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(INFORMATION_CREATE_FAIL({ error: error && error.response && error.response.data }));
    }

    toastr.success('', 'Information created successfully');
    log(createInformation, '<<<<< create information');
    return dispatch(
      INFORMATION_CREATE_SUCCESS({
        information: createInformation.data.data,
      })
    );
  };
};

export const modifyInformation = ({
  id,
  name,
  description,
  siteId,
  categoryId,
  categoryName,
}) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_MODIFY_INIT());
    let updateInformation;
    try {
      updateInformation = await Axios.put(
        `${url}/information/edit`,
        {
          id,
          name,
          description,
          siteId,
          categoryId,
          categoryName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(INFORMATION_MODIFY_FAIL({ error: error && error.response && error.response.data }));
    }

    toastr.success('', 'Information updated successfully');

    return dispatch(
      INFORMATION_MODIFY_SUCCESS({
        information: updateInformation.data.data,
        id,
      })
    );
  };
};

export const informationCleanUp = () => (dispatch) =>
  dispatch(INFORMATION_CLEAN_UP());
