import {
  Button,
  Modal,
  Tooltip,
  Select,
  Empty,
  //  Pagination,
  Collapse,
} from 'antd';
import ModalInformationForm from 'components/ModalInformationForm';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { log } from 'utils';
import {
  createInformation,
  deleteInformation,
  fetchInformation,
  modifyInformation,
} from 'state/actions/information';
import { regexInput } from 'utils/regex';
// import Table from 'components/Table';
import './Information.scss';
import _ from 'underscore';

const { Option } = Select;
const { Panel } = Collapse;

const Information = () => {
  const { siteId } = useParams();

  const {
    informationList,
    informationCategories,
    error,
    loading,
    deleted,
    userData,
    updated,
    role,
  } = useSelector(
    (state) => ({
      informationList: state.information.list,
      informationCategories: state.informationCategories.list,
      error: state.information.error,
      loading: state.information.loading,
      deleted: state.information.deleted,
      userData: state.auth.userData,
      updated: state.information.updated,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  if (informationList) {
    log(informationList, '<<< infor list');
  }

  const [deleteModal, setDeleteModal] = useState({
    informationId: null,
    isOpen: false,
  });

  const defaultInformation = {
    name: '',
    description: '',
    categoryId: '',
    categoryName: '',
  };

  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [information, setInformation] = useState(defaultInformation);
  const [category, setCategory] = useState();
  const [searchError, setSearchError] = useState(null);
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(16);

  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchInformation({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchInformation({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        informationId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchInformation({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = !role && <Redirect to={paths.ROOT} />;

  const onCreate = (values) => {
    const action = isEditing ? modifyInformation : createInformation;
    dispatch(action({ uid, ...values, siteId }));
  };

  const onRemoveButtonClickHandler = (informationId) => {
    setDeleteModal((prevState) => ({
      informationId,
      isOpen: !prevState.isOpen,
    }));
  };

  // const handleChangePage = (value) => {
  //   if (value <= 1) {
  //     setMinValue(0);
  //     setMaxValue(16);
  //   } else {
  //     setMinValue(maxValue);
  //     setMaxValue(value * 16);
  //   }
  // };

  const onCloseModalHandler = () => {
    setDeleteModal({ informationId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteInformation({ id: deleteModal.informationId, siteId }));
  };

  const onNewInformationHandler = () => {
    setInformation(defaultInformation);
    setIsEditing(false);
    setVisible(true);
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const dataByCategory = category
    ? // eslint-disable-next-line
      informationList.filter((el) => {
        if (el.categoryId === category) {
          return el;
        } else if (category === 'All') {
          return informationList;
        }
      })
    : informationList;

  const filteredData = search
    ? informationList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : dataByCategory;

  const confirmMessage = useFormatMessage('Information.confirm');

  const permDeleteMessage = useFormatMessage('Information.permDelete');

  return (
    <section className="information">
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        >
          {permDeleteMessage}
        </Modal>
      )}

      {visible && (
        <ModalInformationForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          information={information}
          setInformation={setInformation}
          isEditing={isEditing}
        />
      )}
      <div className="information__nav">
        <div className="information-dropdown">
          <span>Filter by: </span>
          <Select defaultValue="All" onChange={(id) => setCategory(id)}>
            <Option value="All">All</Option>
            {informationCategories &&
              informationCategories.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className="information__nav-wrapper">
          <div className="information__search-wrapper">
            <div className="information__search">
              <i className="feather-search"></i>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {searchError && <div className="search-error">{searchError}</div>}
          </div>

          <Button
            type="primary"
            className="button-primary"
            onClick={onNewInformationHandler}
          >
            {useFormatMessage('Information.newInformation')}
          </Button>
        </div>
      </div>

        {filteredData.length > 0 ? (
          <Collapse>
            {Array.from(
              Object.keys(_.groupBy(filteredData, 'categoryName')).map(
                (categoryName, index) => {
                  const groupedInformation = _.groupBy(
                    filteredData,
                    'categoryName'
                  );
                  console.log({groupedInformation})

                  return (
                    <Panel
                      key={index}
                      header={categoryName}
                      className="information__header"
                    >
                      <div className='information__content'>
                        {groupedInformation[categoryName].map((info) => {
                          return (
                            <div className={`information__card`} key={info._id}>
                              <div>
                                <div className="information__details">
                                  <h3 className="information__title">
                                    {info.name}
                                  </h3>
                                  <p className="information__category">
                                    {info.categoryName}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <Tooltip title="Delete">
                                  <button
                                    onClick={() =>
                                      onRemoveButtonClickHandler(info._id)
                                    }
                                  >
                                    <i className="feather-trash-2" />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <button
                                    onClick={() => {
                                      setIsEditing(true);
                                      setVisible(true);
                                      setInformation(info);
                                    }}
                                  >
                                    <i className="feather-edit" />
                                  </button>
                                </Tooltip>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Panel>
                  );
                }
              )
            )}
          </Collapse>
        ) : (
          <div className="information__empty-state">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>No Information available, please create new one</span>
              }
            />
          </div>
        )}
        {error && 'Show error'}
      {/* <div className="information__pagination">
        <Pagination
          defaultCurrent={1}
          total={filteredData.length}
          pageSize={16}
          onChange={handleChangePage}
        />
      </div> */}
    </section>
  );
};

export default Information;
