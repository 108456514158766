import {
  Breadcrumb,
  Pagination,
  Select,
  Table,
  DatePicker,
  InputNumber,
  Button,
} from 'antd';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchAuthLogs, downloadAuthLogs } from 'state/actions/log';
import { fetchUsers } from 'state/actions/users';
import './Logs.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function AuthLogs({ match }) {
  const [dropdown, setDropdown] = useState();
  const [category, setCategory] = useState();
  const [dropdownType, setFilterType] = useState('All');
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const page = 1;
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const {
    logsList,
    role,
    loading,
    deleted,
    error,
    organizationList,
    userList,
    downloading,
  } = useSelector(
    (state) => ({
      organizationList: state.organizations.list,
      logsList: state.logs.list,
      userList: state.users.list,
      loading: state.logs.loading,
      deleted: state.logs.deleted,
      role: state.auth.userData.role,
      error: state.logs.error,
      downloading: state.logs.downloading,
    }),
    shallowEqual
  );
  const filterCategory = ['Organization', 'User'];

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchAuthLogs({ page, size: limit }));
    }
  }, [dispatch, role]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLimit = (value) => {
    setLimit(value);
  };

  const onSearch = () => {
    const { startDate, endDate } = dateRange;
    dispatch(fetchAuthLogs({ page, size: limit, startDate, endDate }));
  };

  const handlePagination = (e) => {
    const { startDate, endDate } = dateRange;
    dispatch(fetchAuthLogs({ page: e, size: limit, startDate, endDate }));
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    setDateRange((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  useEffect(() => {
    if (logsList) {
      const arrayOfDropdown = [];
      switch (dropdownType) {
        case 'Organization':
          setCategory('All');
          const organizationLogs =
            logsList.docs &&
            logsList.docs
              .filter((data) => data.organizationId)
              .filter(
                (v, i, a) =>
                  a.findIndex((t) => t.organizationId === v.organizationId) ===
                  i
              );

          for (let i = 0; i < organizationLogs.length; i++) {
            for (let j = 0; j < organizationList.length; j++) {
              if (
                organizationLogs[i].organizationId === organizationList[j]._id
              ) {
                arrayOfDropdown.push({
                  categoryId: organizationList[j]._id,
                  name: organizationList[j].name,
                });
              }
            }
          }
          break;
        case 'User':
          setCategory('All');
          const filteredLogs =
            logsList.docs &&
            logsList.docs
              .filter((data) => data.email)
              .filter(
                (v, i, a) => a.findIndex((t) => t.email === v.email) === i
              );

          for (let i = 0; i < filteredLogs.length; i++) {
            for (let j = 0; j < userList.length; j++) {
              if (filteredLogs[i].email === userList[j].email) {
                arrayOfDropdown.push({
                  categoryId: userList[j].email,
                  name: userList[j].name ?? userList[j].email,
                });
              }
            }
          }
          break;

        default:
          setCategory('All');
          break;
      }
      setDropdown(arrayOfDropdown);
    }
  }, [logsList, dropdownType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchAuthLogs());
    }
  }, [loading, deleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = () => {
    dispatch(downloadAuthLogs());
  };

  useEffect(() => {
    dispatch(fetchUsers(match.params.id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const dataByCategory =
    logsList.docs && category && dropdownType
      ? // eslint-disable-next-line
        logsList.docs.filter((el) => {
          if (
            dropdownType === 'Organization'
              ? el.organizationId === category
              : el.email === category
          ) {
            return el;
          } else if (category === 'All') {
            return logsList;
          }
        })
      : logsList.docs;

  const filteredData = search
    ? dataByCategory.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.message.toLowerCase().includes(search.toLowerCase());
      })
    : dataByCategory;

  const columns = [
    {
      title: useFormatMessage('Logs.email'),
      dataIndex: 'email',
    },
    {
      title: useFormatMessage('Logs.browser'),
      dataIndex: 'browserAgent',
    },
    {
      title: useFormatMessage('Logs.ip'),
      dataIndex: 'ipAddress',
    },
    {
      title: useFormatMessage('Logs.date'),
      dataIndex: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => {
        if (record.date) {
          return (
            <div>{`${moment(record.date)
              .utcOffset('+08:00')
              .format('h:mm:ss A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: useFormatMessage('Logs.status'),
      dataIndex: 'status',
      render: (text, record) => {
        if (record.status === 500 || record.status === 422) {
          return <div>Error</div>;
        } else if (record.status === 201 || record.status === 200) {
          return <div>Success</div>;
        }
      },
    },
    {
      title: useFormatMessage('Logs.message'),
      dataIndex: 'message',
      render: (text, record) => {
        return <span>{record.message}</span>;
      },
    },
  ];

  return (
    <section className="logs">
      {redirect}
      <div className="logs-header">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Authentication Logs
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Authentication Logs</h1>
        </div>
      </div>
      <div className="logs-menu">
        <div className="logs-dropdown">
          <span>Filter by: </span>
          <Select defaultValue="All" onChange={(id) => setFilterType(id)}>
            <Option value="All">All</Option>
            {filterCategory &&
              filterCategory.map((data) => (
                <Option key={data} value={data}>
                  {data}
                </Option>
              ))}
          </Select>
          {/* <span className="logs-dropdown span"></span> */}
          {dropdownType !== 'All' && dropdown && (
            <Select
              value={category}
              defaultValue="All"
              onChange={(id) => setCategory(id)}
            >
              <Option value="All">All</Option>
              {dropdown &&
                dropdown.map((data) => (
                  <Option key={data.categoryId} value={data.categoryId}>
                    {data.name}
                  </Option>
                ))}
            </Select>
          )}
        </div>
        <div className="search-bar">
          <i className="feather-search" />
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="logs-download">
          {downloading ? (
            <button className="button-secondary button-loading mr-1">
              <i className="mdi mdi-spin mdi-loading" />
              Loading...
            </button>
          ) : (
            <button className="button-secondary mr-1" onClick={handleDownload}>
              <i className="feather-download" />
              Download CSV
            </button>
          )}
        </div>
      </div>

      <div className="logs-search">
        <div className="logs-limit">
          <label className="logs-limit-label">Total Data: </label>
          <InputNumber
            value={limit}
            onChange={handleLimit}
            className="logs-limit-input"
          />
        </div>

        <RangePicker className="logs-date-picker" onChange={handleDateChange} />

        <div className="logs-btn">
          <Button type="primary" className="btn-primary" onClick={onSearch}>
            Search
          </Button>
        </div>
      </div>

      <div className="logs__table">
        {loading && filteredData ? (
          <ClipLoader />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="_id"
            pagination={false}
          />
        )}
        {error && 'Show error'}
        <div className="logs-pagination">
          {logsList && (
            <Pagination
              defaultCurrent={1}
              total={logsList.totalDocs}
              pageSize={limit}
              onChange={handlePagination}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
    </section>
  );
}
