import React, { useState, useEffect } from 'react';
import './ScreenSettings.css';
import { Switch, Slider, Skeleton } from 'antd';
import socketIoClient from 'socket.io-client';
import {url} from 'utils/url';
import { log } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScreens } from 'state/actions/screens';
import {
  connectSocket,
  changeVolume,
  changeMute,
  changeDisplay,
  screenShot,
  forceUpdate,
  restartScreen
} from 'state/actions/settings';
import { useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Select, Form, Empty } from 'antd';

function ScreenSettings({ handleChangeTab }) {

  const { siteId } = useParams();

  const { screenList, screenLoading } = useSelector((state) => ({
    screenList: state.screens.list,
    screenLoading: state.screens.loading,
  }));

  const [selectedScreen, setSelectedScreen] = useState('');
  const [socket, setSocket] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [captureLoading, setCaptureLoading] = useState(false)

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState({
    device: true,
    sound: true,
    picture: true,
  });

  const [screenshoot, setScreenshoot] = useState(null);

  const [soundInfo, setSoundInfo] = useState({
    level: 0,
    muted: false,
  });

  const [pictureInfo, setPictureInfo] = useState({
    brightness: 0,
    backlight: 0,
    contrast: 0,
  });


  const dispatch = useDispatch();
  const data = {
    name: "adsign"
  }

  // const onChangeHandler = useChangeHandler(setDownloadUrl);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    if (!deviceInfo) {
      setError(true)
    }
  }, [deviceInfo])

  useEffect(() => {
    setSocket(socketIoClient(url, {
      query: {
        name: 'adsign',
      },
    }))
  }, [])

  useEffect(() => {
    setDeviceInfo(null)
    if (socket && selectedScreen) {
      socket.on("connect", () => {
        socket.emit("message", data)
      })
      socket.on('in_device', (data) => {
        log(data);
        if (data.screenId === selectedScreen) {
          setError(false)
          setDeviceInfo(data);
          getSoundInfo();
          setLoading({
            ...loading,
            device: false,
          });
        } else {
          setDeviceInfo(null);
          setLoading({
            device: true,
            sound: true,
            picture: true,
          });
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, selectedScreen])

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
  }, [dispatch, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedScreen) {
      connectSocket(selectedScreen);
    }
  }, [selectedScreen]);

  const handleTryAgain = () => {
    connectSocket(selectedScreen);
    setLoading({
      device: true,
      sound: true,
      picture: true,
    });
    setError(false);
    getDeviceInfo();
  };

  const getDeviceInfo = async () => {
    log('get device info')
    socket.emit("message", data, (response) => {
      log(response); // ok
    });
    await delay()
    if (!deviceInfo) {
      setError(true)
    }
  };

  const getSoundInfo = () => {
    socket.once('in_sound', (data) => {
      setSoundInfo(data);
      setLoading({
        ...loading,
        device: false,
        sound: false,
      });
      getPictureInfo();
    });
  };

  const getPictureInfo = () => {
    socket.once('in_picture', (data) => {
      log(data)
      setPictureInfo(data);
      setLoading({
        ...loading,
        device: false,
        sound: false,
        picture: false,
      });
    });
  };

  const handleChangeVolume = (value) => {
    changeVolume(selectedScreen, value)
    setSoundInfo({
      ...soundInfo,
      level: value,
    });
  };

  const handleChangeMuted = (checked) => {
    changeMute(selectedScreen, checked)
    setSoundInfo({
      ...soundInfo,
      muted: checked,
    });
  };

  const handleChangeBacklight = (value) => {
    changeDisplay(selectedScreen, { name: 'backlight', value })
    setPictureInfo({
      ...pictureInfo,
      backlight: value,
    });
  };

  const handleChangeContrast = (value) => {
    changeDisplay(selectedScreen, { name: 'contrast', value })
    setPictureInfo({
      ...pictureInfo,
      contrast: value,
    });
  };

  const handleChangeBrightness = (value) => {
    changeDisplay(selectedScreen, { name: 'brightness', value })
    setPictureInfo({
      ...pictureInfo,
      brightness: value,
    });
  };

  const handleCapture = () => {
    screenShot(selectedScreen)
    setCaptureLoading(true)
    socket.once('in_capture', (data) => {
      setCaptureLoading(false)
      if (data.screenId === selectedScreen) {
        setScreenshoot(data.img);
      }
    });
  };

  // const handleInstallApplication = () => {
  //   installApplication(selectedScreen, downloadUrl.name)
  //   socket.once('in_install', (data) => {
  //     log(data)
  //   });
  // };

  const handleRestartScreen = () => {
    restartScreen(selectedScreen)
    socket.once('in_restart', (data) => {
      log(data)
    });
  };

  const handleForceUpdate = () => {
    forceUpdate(selectedScreen)
    socket.once('in_update', (data) => {
      log(data)
    });
  };

  const handleChangeScreen = (value) => {
    setDeviceInfo(null);
    setSelectedScreen(value);
  };

  return (

    <div className="screen">
      {screenLoading ? (
        <ClipLoader />
      ) : (
          <div>
            {screenList.length > 0 ? (
              <div>
                <div style={{ width: 200, marginBottom: '2rem' }}>
                  <Form layout="vertical">
                    <Form.Item label="Select Screen">
                      <Select
                        onChange={handleChangeScreen}
                        placeholder="Select Screen"
                        style={{ width: 200 }}
                      >
                        {screenList.map((screen) => {
                          return (
                            <Select.Option key={screen._id} value={screen._id}>
                              {screen.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form>
                </div>

                {selectedScreen && (
                  <>
                    {log(error, 'error')}
                    {error ? (
                      <div>
                        <h3 className="screen-title">The Device is not connected</h3>
                        <button onClick={handleTryAgain} className="button-primary">
                          Try Again
                  </button>
                      </div>
                    ) : (
                        <>
                          <div className="screen-content">
                            <div className="screen-column">
                              <p className="screen-title">Device Information</p>
                              <div className="screen-table">
                                {loading.device ? (
                                  <Skeleton loading />
                                ) : (
                                    <>
                                      <div>
                                        <div>Manufacture</div>
                                        <div>{deviceInfo && deviceInfo.manufacturer}</div>
                                      </div>
                                      <div>
                                        <div>SDK Version</div>
                                        <div>{deviceInfo && deviceInfo.sdkVersion}</div>
                                      </div>
                                      <div>
                                        <div>Model Name</div>
                                        <div>{deviceInfo && deviceInfo.modelName}</div>
                                      </div>
                                      <div>
                                        <div>Serial Number</div>
                                        <div>{deviceInfo && deviceInfo.serialNumber}</div>
                                      </div>
                                      <div>
                                        <div>Firmware Version</div>
                                        <div>{deviceInfo && deviceInfo.firmwareVersion}</div>
                                      </div>
                                      <div>
                                        <div>Hardware Version</div>
                                        <div>{deviceInfo && deviceInfo.hardwareVersion}</div>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                            <div className="screen-column">
                              <p className="screen-title">Sound Configuration</p>
                              <div className="screen-list">
                                {loading.sound ? (
                                  <Skeleton loading />
                                ) : (
                                    <>
                                      <div>
                                        <p className="screen-p">Volume</p>
                                        <Slider
                                          disabled={soundInfo.muted}
                                          onChange={handleChangeVolume}
                                          value={soundInfo.level}
                                          tooltipVisible
                                          min={0}
                                          max={100}
                                        />
                                      </div>
                                      <div className="screen-justify">
                                        <div>
                                          <p className="screen-p">Muted</p>
                                        </div>
                                        <div>
                                          <Switch
                                            onChange={handleChangeMuted}
                                            checked={soundInfo.muted}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="screen-content">
                            <div className="screen-column">
                              <p className="screen-title">Display Configuration</p>
                              <div className="screen-list">
                                {loading.picture ? (
                                  <Skeleton loading />
                                ) : (
                                    <>
                                      <div>
                                        <p className="screen-p">Backlight</p>
                                        <Slider
                                          onChange={handleChangeBacklight}
                                          value={pictureInfo.backlight}
                                          tooltipVisible
                                          min={0}
                                          max={100}
                                        />
                                      </div>
                                      <div>
                                        <p className="screen-p">Contrast</p>
                                        <Slider
                                          onChange={handleChangeContrast}
                                          value={pictureInfo.contrast}
                                          tooltipVisible
                                          min={0}
                                          max={100}
                                        />
                                      </div>
                                      <div>
                                        <p className="screen-p">Brightness</p>
                                        <Slider
                                          onChange={handleChangeBrightness}
                                          value={pictureInfo.brightness}
                                          tooltipVisible
                                          min={0}
                                          max={100}
                                        />
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>

                            <div className="screen-column">
                              <p className="screen-title">Take a screenshot</p>
                              {loading.picture && loading.sound && loading.picture ? (
                                <Skeleton.Image loading />
                              ) : (
                                  <>
                                    <div className="screen-shoot">
                                      {screenshoot ? (
                                        <img src={screenshoot} alt="Screenshoot" />
                                      ) : (
                                          <i className="feather-camera" />
                                        )}
                                    </div>
                                    <button
                                      onClick={handleCapture}
                                      className={captureLoading ? "button is-loading has-background-grey" : "screen-button"}
                                      disabled={captureLoading}
                                    >
                                      Capture
                          </button>
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="screen-content">
                            <div className="screen-column">
                              <div className="screen-button-update">
                                {/* <p className="screen-title">Install Application</p>
                                <div>
                                  <Input
                                    placeholder="IPK Location"
                                    name="name"
                                    type="text"
                                    value={downloadUrl.name}
                                    onChange={onChangeHandler}
                                  />
                                </div>
                                <div>
                                  <button
                                    onClick={handleInstallApplication}
                                    className="install-button">
                                    Install Application
                                  </button>
                                </div> */}
                                <div>
                                  <button
                                    onClick={handleRestartScreen}
                                    className="install-button">
                                    Restart Screen
                                  </button>
                                </div>
                                <div>
                                  <button
                                    onClick={handleForceUpdate}
                                    className="install-button">
                                    Force Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </div>
            ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <span>
                      No Screen available, please create new one <br />{' '}
                      <button
                        className="button-primary mt-2"
                        onClick={() => handleChangeTab('screens')}
                      >
                        <i className="feather-plus" />
                        Add new screen
              </button>
                    </span>
                  }
                />
              )
            }
          </div>)}
    </div>
  );
}

export default ScreenSettings;
