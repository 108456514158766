import MediaForm from 'components/MediaForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import randomFlatColors from 'random-flat-colors';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { createMedia, fetchMedia, modifyMedia } from 'state/actions/media';
import { log } from 'utils';
import './Media.css';

function Media({ history }) {
  const { id, siteId, siteName } = useParams();
  const { success, mediaList, mediaData, sitesList, error } = useSelector(
    (state) => ({
      success: state.media.success,
      mediaList: state.media.list,
      mediaData: state.media.media,
      sitesList: state.sites.list,
      error: state.media.error,
    }),
    shallowEqual
  );

  const [media, setMedia] = useState(mediaData || {});
  const [dates, setDates] = useState([]);
  const [days, setDays] = useState([
    {
      name: 'Sunday',
      alias: 'Sun',
      isActive: true,
      hours: [['', '23.59']],
    },
    {
      name: 'Monday',
      alias: 'Mon',
      isActive: true,
      hours: [['', '23.59']],
    },
    {
      name: 'Tuesday',
      alias: 'Tue',
      isActive: true,
      hours: [['', '23.59']],
    },
    {
      name: 'Wednesday',
      alias: 'Wed',
      isActive: true,
      hours: [['', '23.59']],
    },
    {
      name: 'Thursday',
      alias: 'Thu',
      isActive: true,
      hours: [['', '23.59']],
    },
    {
      name: 'Friday',
      alias: 'Fri',
      isActive: true,
      hours: [['', '23.59']],
    },
    {
      name: 'Saturday',
      alias: 'Sat',
      isActive: true,
      hours: [['', '23.59']],
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      setMedia({
        ...media,
        color: randomFlatColors(),
      });
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      const mediaFetched = mediaList.find(
        (fetchedMedia) => fetchedMedia.id === id
      );
      if (mediaFetched) {
        if (mediaFetched.type === 'day') {
          const newDays = [...days];

          // eslint-disable-next-line
          newDays.map((_, index) => {
            newDays[index].isActive = false;
          });

          log(newDays);

          mediaFetched.days &&
            // eslint-disable-next-line
            mediaFetched.days.map((day) => {
              const findDay = newDays.find((x) => x.name === day.day);
              // eslint-disable-next-line
              findDay.hours = day.hours.map((hour) => {
                return hour.split('-');
              });
            });

          setDays(newDays);
        } else if (mediaFetched.type === 'date') {
          const newDates =
            mediaFetched.dates &&
            mediaFetched.dates.map((date) => {
              return {
                isActive: true,
                date: moment(date.date),
                hours: date.hours.map((hour) => {
                  return hour.split('-');
                }),
              };
            });
          setDates(newDates);
        }
        setMedia(mediaFetched);
      } else if (mediaData.id === id) {
        if (mediaData.type === 'day') {
          const newDays = [...days];
          // eslint-disable-next-line
          newDays.map((_, index) => {
            newDays[index].isActive = false;
          });
          mediaData.days &&
            // eslint-disable-next-line
            mediaData.days.map((day) => {
              const findDay = newDays.find((x) => x.name === day.day);
              findDay.isActive = true;
              // eslint-disable-next-line
              findDay.hours = day.hours.map((hour) => {
                return hour.split('-');
              });
            });
          setDays(newDays);
        } else if (mediaData.type === 'date') {
          const newDates =
            mediaData.dates &&
            mediaData.dates.map((date) => {
              return {
                isActive: true,
                date: moment(date.date),
                hours: date.hours.map((hour) => {
                  return hour.split('-');
                }),
              };
            });
          setDates(newDates);
        }
        setMedia(mediaData);
      } else {
        dispatch(fetchMedia({ mediaId: id }));
      }
    }
  }, [id, mediaData]); // eslint-disable-line react-hooks/exhaustive-deps

  const editMediaMessage = useFormatMessage('Media.editMedia');

  const newMediaMessage = useFormatMessage('Media.newMedia');

  const isEditing = !!id;

  const mediaForm =
    !media && id ? (
      <ClipLoader />
    ) : (
      <MediaForm
        isEditing={isEditing}
        media={media}
        sites={sitesList}
        setMedia={setMedia}
        action={isEditing ? modifyMedia : createMedia}
        days={days}
        setDays={setDays}
        dates={dates}
        setDates={setDates}
        success={success}
        history={history}
        id={id}
        error={error}
      />
    );

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editMediaMessage : newMediaMessage}</h1>
      </div>
      <div className="media-breadcrumb">
        <ul>
          <li>
            <Link to={`/${siteId}/information/${siteName}`}>Media</Link>
          </li>
          <li>{media._id ? 'Edit Media' : 'Add new Media'}</li>
        </ul>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{mediaForm}</div>
    </div>
  );
}

export default Media;
