import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  FLOOR_FETCH_DATA_INIT,
  FLOOR_FETCH_DATA_SUCCESS,
  FLOOR_FETCH_DATA_FAIL,
  FLOOR_DELETE_FLOOR_INIT,
  FLOOR_DELETE_FLOOR_SUCCESS,
  FLOOR_DELETE_FLOOR_FAIL,
  FLOOR_CLEAR_DATA,
  FLOOR_CREATE_FLOOR_INIT,
  FLOOR_CREATE_FLOOR_SUCCESS,
  FLOOR_CREATE_FLOOR_FAIL,
  FLOOR_MODIFY_FLOOR_INIT,
  FLOOR_MODIFY_FLOOR_SUCCESS,
  FLOOR_MODIFY_FLOOR_FAIL,
  FLOOR_CLEAN_UP,
  FLOOR_CLEAR_DATA_LOGOUT,
} from 'state/actions/floor';

const initialState = {
  list: [],
  floor: {
    name: '',
    description: '',
    siteId: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const floorReducer = createReducer(
  {
    [FLOOR_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [FLOOR_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      floor: payload.floor || initialState.floor,
      loading: false,
      error: null,
    }),
    [FLOOR_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FLOOR_DELETE_FLOOR_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FLOOR_DELETE_FLOOR_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [FLOOR_DELETE_FLOOR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FLOOR_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [FLOOR_CREATE_FLOOR_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FLOOR_CREATE_FLOOR_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.floor),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [FLOOR_CREATE_FLOOR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FLOOR_MODIFY_FLOOR_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FLOOR_MODIFY_FLOOR_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.name,
                description: payload.description,
                siteId: payload.floor.siteId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.floor.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [FLOOR_MODIFY_FLOOR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FLOOR_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [FLOOR_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
