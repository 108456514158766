/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Select, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { poisCleanUp } from 'state/actions/pois';
import { regexDescription, regexInput, regexUrl } from 'utils/regex';
import { fetchFloor } from 'state/actions/floor';
import {imgUrl} from 'utils/url';
import { log } from 'utils';
import './PoiForm.css';
import './PoiForm.scss';
import { fetchCategories } from 'state/actions/categories';

const PoiForm = ({ poi, setPoi, action, success, history, id, error }) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
    console.log(error);
  }, [error]);

  const { loading, userData, categoriesList, floorList } = useSelector(
    (state) => ({
      loading: state.pois.loading,
      userData: state.auth.userData,
      categoriesList: state.categories.list,
      floorList: state.floor.list,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  if (poi) {
    log(poi, '<<<< POI');
  }

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(poisCleanUp());
  }, [dispatch]);

  useEffect(() => {
    // fetch floor and poi categories
    dispatch(fetchFloor({ siteId }));
    dispatch(fetchCategories({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = useChangeHandler(setPoi);

  const onFileChangedHandler = (file) => {
    setPoi((prevState) => ({ ...prevState, file, logoUrl: null }));
  };

  const handleDeletePreviewImage = () => {
    setPoi((prevState) => ({
      ...prevState,
      file: null,
      logoUrl: null,
      logo: null,
    }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (
      (!regexInput(poi.name) && poi.name) ||
      (!regexInput(poi.phone) && poi.phone) ||
      (!regexInput(poi.priority) && poi.priority) ||
      (!regexUrl(poi.url) && poi.url) ||
      (!regexInput(poi.location) && poi.location) ||
      (!regexDescription(poi.description) && poi.description) ||
      (!regexInput(poi.floor) && poi.floor)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(poi.name) &&
        poi.name && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      newErrors.errors.phone = !regexInput(poi.phone) &&
        poi.phone && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      newErrors.errors.priority = !regexInput(poi.priority) &&
        poi.priority && [
          { msg: 'This field cannot contain a special characters' },
        ];
      newErrors.errors.url = !regexUrl(poi.url) &&
        poi.url && [{ msg: 'This field cannot contain a special characters' }];
      newErrors.errors.location = !regexInput(poi.location) &&
        poi.location && [
          { msg: 'This field cannot contain a special characters' },
        ];
      newErrors.errors.description = !regexDescription(poi.description) &&
        poi.description && [
          { msg: 'This field cannot contain a special characters' },
        ];
      newErrors.errors.floor = !regexInput(poi.floor) &&
        poi.floor && [
          { msg: 'This field cannot contain a special characters' },
        ];

      console.log(newErrors);
      setErrors(newErrors);
    } else {
      dispatch(
        action({
          uid,
          ...poi,
          siteId,
          id,
        })
      );
    }
  };


  const onSelectCategoryHandler = (value) => {
    const categoryId = value.value;
    const categoryName = value.label;
    setPoi((prevState) => ({
      ...prevState,
      categoryId,
      categoryName,
    }));
  };

  const onSelectFloorHandler = (value) => {
    const floorId = value.value;
    const floorName = value.label;
    setPoi((prevState) => ({
      ...prevState,
      floorId,
      floorName,
    }));
  };

  // const canSubmit = poi.name && poi.description;

  const imagePreviewUrl = poi.file
    ? poi.file && URL.createObjectURL(poi.file)
    : poi.logo && `${imgUrl}/poi/${poi.logo}`;

  // const goBackMessage = useFormatMessage('PoiForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('PoiForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('PoiForm.pickFile');

  const handleReset = () => {
    setPoi({
      ...poi,
      category: '',
      description: '',
      floor: '',
      location: '',
      logo: null,
      name: '',
      opentime: '',
      phone: '',
      priority: '',
      siteId: '',
      tag: '',
      url: '',
    });
  };

  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

  return (
    <div className="poi-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="POI Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="name"
              value={poi.name}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Poi Category <span className="is-required">*</span>
            </label>
            <Select
              labelInValue
              placeholder="Select Category"
              className={`${
                errors && errors.errors && errors.errors.categoryId
                  ? 'has-error'
                  : ''
              }`}
              name="categoryId"
              value={
                poi.categoryId
                  ? { value: poi.categoryId, label: trimString(poi.categoryName, 50) }
                  : null
              }
              onChange={onSelectCategoryHandler}
            >
              {categoriesList
                .sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
                .map((category) => {
                  return (
                    <Select.Option key={category._id} value={category._id}>
                      {category.name}
                    </Select.Option>
                  );
                })}
            </Select>
            {errors && errors.errors && errors.errors.categoryId && (
              <span className="error-message">
                {errors.errors.categoryId[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Floor <span className="is-required">*</span>
            </label>
            <Select
              labelInValue
              placeholder="Select Floor"
              className={`${
                errors && errors.errors && errors.errors.floorId
                  ? 'has-error'
                  : ''
              }`}
              name="floorId"
              value={
                poi.floorId
                  ? { value: poi.floorId, label: poi.floorName }
                  : { value: 'none' }
              }
              onChange={onSelectFloorHandler}
            >
              <Select.Option value="none">N/A</Select.Option>
              {floorList
                .sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
                .map((floor) => {
                  return (
                    <Select.Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Select.Option>
                  );
                })}
            </Select>
            {errors && errors.errors && errors.errors.floorId && (
              <span className="error-message">
                {errors.errors.floorId[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <div className="form-split">
              <div style={{ width: '100%' }}>
                <label className="form-label">Open Time</label>
                <div className="form-group">
                  <InputMask
                    mask="99:99-99:99"
                    placeholder="00:00-00:00"
                    type="text"
                    name="opentime"
                    value={poi.opentime}
                    onChange={onChangeHandler}
                    className={`ant-input ${
                      errors && errors.errors && errors.errors.opentime
                        ? 'has-error'
                        : ''
                    }`}
                  />
                </div>
                {errors && errors.errors && errors.errors.opentime && (
                  <span className="error-message">
                    {errors.errors.opentime[0].msg}
                  </span>
                )}
              </div>
              <div>
                <label className="form-label">Phone Number</label>
                <Input
                  placeholder="POI Phone Number"
                  className={`${
                    errors && errors.errors && errors.errors.phone
                      ? 'has-error'
                      : ''
                  }`}
                  name="phone"
                  value={poi.phone}
                  onChange={onChangeHandler}
                  type="number"
                />
                {errors && errors.errors && errors.errors.phone && (
                  <span className="error-message">
                    {errors.errors.phone[0].msg}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form">
            <label className="form-label">Logo</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload (Max file size 50mb)</p>
                </div>
              </Upload>
            )}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">URL</label>
            <Input
              placeholder="POI URL"
              className={`${
                errors && errors.errors && errors.errors.url ? 'has-error' : ''
              }`}
              name="url"
              value={poi.url}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.url && (
              <span className="error-message">{errors.errors.url[0].msg}</span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Location</label>
            <Input
              placeholder="POI Location"
              className={`${
                errors && errors.errors && errors.errors.location
                  ? 'has-error'
                  : ''
              }`}
              name="location"
              value={poi.location}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.location && (
              <span className="error-message">
                {errors.errors.location[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="POI Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="description"
              value={poi.description}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="button-primary" onClick={onSubmitHandler}>
              Save
            </button>
          )}
          <button className="button-secondary" onClick={handleReset}>
            Reset
          </button>
        </div>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
    </div>
  );
};

PoiForm.propTypes = {
  poiData: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    opentime: PropTypes.string,
    url: PropTypes.string,
    category: PropTypes.string,
    siteId: PropTypes.string,
    tag: PropTypes.string,
    location: PropTypes.string,
    floor: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default PoiForm;
