import { Modal, Table, Tooltip } from 'antd';
import ModalScreenForm from 'components/ModalScreenForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { log } from 'utils';
import {
  createScreen,
  deleteScreen,
  fetchScreens,
  modifyScreen,
} from 'state/actions/screens';
import { clearUsersData } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import './Screens.scss';

export default function Screens() {
  const { siteId } = useParams();

  const {
    // isAdmin,
    sites,
    error,
    loading,
    deleted,
    updated,
    screensList,
    userData,
    role,
  } = useSelector(
    (state) => ({
      // isAdmin: state.auth.userData.isAdmin,
      sites: state.sites.list,
      error: state.screens.error,
      loading: state.screens.loading,
      deleted: state.screens.deleted,
      updated: state.screens.updated,
      screensList: state.screens.list,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const defaultScreen = {
    name: '',
    description: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: userData.email ? userData.email : '',
  };

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [screen, setScreen] = useState(defaultScreen);
  const [siteName, setSiteName] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [searchError, setSearchError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
    return () => dispatch(clearUsersData());
  }, [dispatch, role, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sites && siteId) {
      const site = sites.filter((site) => site._id === siteId)[0];
      site && setSiteName(site.name);
      site && setOrganizationId(site.organizationId);
    }
  }, [sites, siteId]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchScreens({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchScreens({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadJSONConfig = (selectedScreen) => {
    const json = {
      organizationId: organizationId,
      siteId: siteId,
      screenId: selectedScreen._id,
      email: '',
      password: '',
      url: 'https://premise-api.adactive.asia',
      mode: 'offline',
    };
    const content = JSON.stringify(json);
    const filename = `${siteName} ${selectedScreen.name}.json`;
    const contentType = 'text/json';

    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyScreen : createScreen;
    log(values, '<<<< values screen oncreate');
    dispatch(action(values));
  };

  const onNewScreenHandler = () => {
    log('handle new screen');
    setScreen(defaultScreen);
    setIsEditing(false);
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (screenId) => {
    setDeleteModal((prevState) => ({
      screenId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ screenId: null, isOpen: false });
  };

  const onDeleteScreenHandler = () => {
    dispatch(deleteScreen(deleteModal.screenId));
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Screen Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Option',
      dataIndex: 'option',
      render: (text, record) => {
        return (
          <div className="screens__actions">
            {role === 'superadmin' && (
              <Tooltip title="Download Screen Configuration">
                <button
                  onClick={() => {
                    downloadJSONConfig(record);
                  }}
                  data-tooltip="Download "
                  className="btn btn--edit"
                >
                  <i className="feather-download"></i>
                </button>
              </Tooltip>
            )}
            <Tooltip title="Edit Screen">
              <button
                onClick={() => {
                  setScreen(record);
                  setVisible(true);
                  setIsEditing(true);
                }}
                data-tooltip="Edit"
                className="btn btn--edit"
              >
                <i className="feather-edit"></i>
              </button>
            </Tooltip>

            <Tooltip title="Delete Screen">
              <button
                type="button"
                onClick={() => onRemoveButtonClickHandler(record._id)}
                data-tooltip="Delete"
                className="btn btn--delete"
              >
                <i className="feather-trash-2"></i>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const data = search
    ? screensList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : screensList;

  const confirmMessage = useFormatMessage('Screens.confirm');

  const permDeleteMessage = useFormatMessage('Screens.permDelete');

  return (
    <section className="screens">
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteScreenHandler}
          onCancel={onCloseModalHandler}
        >
          <p>{permDeleteMessage}</p>
        </Modal>
      )}
      <div className="media-categories__nav">
        <div className="media-categories__search-wrapper">
          <div className="media-categories__search">
            <i className="feather-search"></i>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>

        <button className="button-primary" onClick={onNewScreenHandler}>
          Add new Screen
        </button>
      </div>
      {visible && (
        <ModalScreenForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          screen={screen}
          setScreen={setScreen}
          isEditing={isEditing}
        />
      )}
      <div className="screens__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="_id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
