import React, { useState, useEffect } from 'react';
import './FileManagement.scss';
import { Tooltip, Table, Button, Modal } from 'antd';
import socketIoClient from 'socket.io-client';
import {url} from 'utils/url';
import { log } from 'utils';
import { useFormatMessage } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScreens } from 'state/actions/screens';
import { fetchFiles, createFile, deleteFile } from 'state/actions/files';
import moment from 'moment';
import {
  connectSocket,
  installApplication,
} from 'state/actions/settings';
import { useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Select, Form, Empty } from 'antd';
import ModalFileManagementForm  from 'components/ModalFileManagementForm';

function FileManagement({ handleChangeTab }) {

  const { siteId } = useParams();

  const { screenList, screenLoading, fileList, updated, deleted, loadingFiles } = useSelector((state) => ({
    screenList: state.screens.list,
    screenLoading: state.screens.loading,
    fileList: state.files.list,
    updated: state.files.updated,
    deleted: state.files.deleted,
    loadingFiles: state.files.loading
  }));

  const [selectedScreen, setSelectedScreen] = useState('');
  const [socket, setSocket] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState();
  const [deleteModal, setDeleteModal] = useState({
    fileId: null,
    isOpen: false,
  });


  // const [downloadUrl, setDownloadUrl] = useState('');

  const dispatch = useDispatch();
  const data = {
    name: "adsign"
  }


  // const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    if (!deviceInfo) {
      setError(true)
    }
  }, [deviceInfo])

  useEffect(() => {
    if (updated) {
      setVisible(false)
    }
  }, [updated])

  useEffect(() => {
    setSocket(socketIoClient(url, {
      query: {
        name: 'adsign',
      },
    }))
  }, [])

  useEffect(() => {
    if (deleted && !loadingFiles) {
      setDeleteModal((prevState) => ({
        floorId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchFiles({ id: selectedScreen }));
    }
  }, [deleted, loadingFiles, dispatch, selectedScreen]);

  useEffect(() => {
    if (updated && !loadingFiles) {
      dispatch(fetchFiles({ id: selectedScreen }));
      setVisible(false);
    }
  }, [updated, loadingFiles, dispatch, selectedScreen]);  

  useEffect(() => {
    setDeviceInfo(null)
    if (socket && selectedScreen) {
      socket.on("connect", () => {
        socket.emit("in_device", data)
      })
      socket.on('in_device', (data) => {
        log(data);
        if (data.screenId === selectedScreen) {
          setError(false)
          dispatch(fetchFiles({ id: selectedScreen }));
          setDeviceInfo(data);
          setLoading({
            ...loading,
            device: false,
          });
        } else {
          setDeviceInfo(null);
          
        }
      });
      
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, selectedScreen])
  

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
  }, [dispatch, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (selectedScreen) {
  //     connectSocket(selectedScreen);
      
  //   }
  // }, [dispatch, selectedScreen]);

  const delay = ms => new Promise(res => setTimeout(res, ms));
  
  const confirmMessage = useFormatMessage('FileManagement.confirm');
  const permDeleteMessage = useFormatMessage('FileManagement.permDelete');

  const handleTryAgain = async () => {
    await connectSocket(selectedScreen);
    dispatch(fetchFiles({ id: selectedScreen }));
    setError(false);
    getDeviceInfo();
  };




  const handleInstallApplication = (downloadUrl) => {
    installApplication(selectedScreen, downloadUrl)
    socket.once('in_install', (data) => {
      log(data)
    });
  };

  const getDeviceInfo = async () => {
    log('get device info')
    socket.emit("message", data, (response) => {
      log(response); // ok
    });
    await delay()
    if (!deviceInfo) {
      setError(true)
    }
  };


  const onCreate = () => {
    dispatch(createFile({screenId:selectedScreen,ipk:file}));
  };

  const handleChangeScreen = (value) => {
    setDeviceInfo(null);
    setSelectedScreen(value);
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteFile(deleteModal.fileId));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ fileId: null, isOpen: false });
  };

  const onNewIPK = (value) => {
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (fileId) => {
    setDeleteModal((prevState) => ({
      fileId,
      isOpen: !prevState.isOpen,
    }));
  };

  const newMessage = useFormatMessage("FileManagement.newFile");

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text, record) => {
        if (record.modifiedAt) {
          return (
            <div>{`${moment(record.modifiedAt)
              .utcOffset('+08:00')
              .format('h:mm A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Option',
      dataIndex: 'option',
      render: (text, record) => (
        <div>
          <div className="buttons is-right">
            <Tooltip title="Install IPK">
              <button
                data-tooltip="Install"
                className="btn btn--download"
                onClick={() => {
                  handleInstallApplication(record.url)
                }}
              >
                <i className="feather-download" />
              </button>
            </Tooltip>

            <Tooltip title="Delete Floor">
              <button
                type="button"
                className="btn btn--delete"
                data-tooltip="Delete"
                onClick={() => {
                  onRemoveButtonClickHandler(record._id);
                 }}
              >
                <i className="feather-trash-2" />
              </button>
            </Tooltip>
          </div>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    
    <section className="files-management">
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        >
          {permDeleteMessage}
        </Modal>
      )}
      {visible && (
      <ModalFileManagementForm
        visible={true}
        onCancel={() => {
          setVisible(false);
        }}
        onCreate={onCreate}
        setFile={setFile}
        file={file}
      />
    )}
      {screenLoading ? (
        <ClipLoader />
      ) : (
        <div>
          {screenList.length > 0 ? (
            <div>
              <div style={{ width: 200, marginBottom: '2rem' }}>
                <Form layout="vertical">
                  <Form.Item label="Select Screen">
                    <Select
                      onChange={handleChangeScreen}
                      placeholder="Select Screen"
                      style={{ width: 200 }}
                    >
                      {screenList.map((screen) => {
                        return (
                          <Select.Option key={screen._id} value={screen._id}>
                            {screen.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </div>

              {selectedScreen && (
                <>
                  {log(error, 'error')}
                  {error ? (
                    <div>
                      <h3 className="screen-title">The Device is not connected</h3>
                      <button onClick={handleTryAgain} className="button-primary">
                        Try Again
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="files-management__nav">
                        <Button
                          type="primary"
                          className="button-primary"
                          onClick={onNewIPK}
                        >{newMessage}
                        </Button>
                      </div>
                      <div className='files-management__table'>
                        <Table columns={columns} dataSource={fileList} rowKey="_id" />
                      </div>
                    </div>

                  )}
                </>
              )}
            </div>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  No Screen available, please create new one <br />{' '}
                  <button
                    className="button-primary mt-2"
                    onClick={() => handleChangeTab('screens')}
                  >
                    <i className="feather-plus" />
                    Add new screen
                  </button>
                </span>
              }
            />
          )
          }
        </div>)}
    </section>
  );
}

export default FileManagement;
