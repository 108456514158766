import { Tooltip, Tag } from 'antd';
import { saveAs } from 'file-saver';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { imgUrl } from 'utils/url';
import moment from 'moment';
import { Link } from 'react-router-dom';

const { CheckableTag } = Tag;

const DragElement = SortableElement((props) => {
  const {
    media,
    siteId,
    siteName,
    onPreviewModalClickHandler,
    id,
    // playlist,
    onRemoveButtonClickHandler,
    // onEditCalendarClickHandler,
    handleSelectedMedia,
    selectedMediaTags,
  } = props;

  const trimString = (string, length) => {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  };

 const downloadImage = (url, name, type) => {
    const fileType = type.split('/');
    saveAs(url, `${name}.${fileType[1]}`);
  };

  return (
    <div className="media-library__card-item">
      <div className="media-library__card-header">
        <div className="media-library__card-info">
          <span className="media-library__card-order">
            #{parseInt(id) + 1}
          </span>
          <span className="media-library__card-type">
            {media.logo && media.fileType.split('/')[0] === 'video'
              ? 'Video'
              : 'Image'}
          </span>
        </div>
        <div className="media-library__actions">
          <Tooltip
            title="Media Preview"
            className="media-library__media-preview"
          >
            <button
              onClick={() => {
                onPreviewModalClickHandler(media);
              }}
            >
              <i className="feather-maximize-2" />
            </button>
          </Tooltip>
        </div>
      </div>

      <CheckableTag
        checked={selectedMediaTags.indexOf(media) > -1}
        onChange={(checked) => handleSelectedMedia(media, checked)}
      >
        {selectedMediaTags.indexOf(media) > -1 && (
          <div className="checked-overlay">
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-checked"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6095 1.05719C24.1302 1.57789 24.1302 2.42211 23.6095 2.94281L8.94281 17.6095C8.42211 18.1302 7.57789 18.1302 7.05719 17.6095L0.390524 10.9428C-0.130175 10.4221 -0.130175 9.57789 0.390524 9.05719C0.911223 8.53649 1.75544 8.53649 2.27614 9.05719L8 14.7811L21.7239 1.05719C22.2446 0.536493 23.0888 0.536493 23.6095 1.05719Z"
                fill="white"
              />
            </svg>
          </div>
        )}

        {media.logo && media.fileType.split('/')[0] === 'image' ? (
          <img
            src={`${imgUrl}/media/${media.logo}`}
            alt="default"
            className="media-library__card-img"
          />
        ) : media.logo && media.fileType.split('/')[0] === 'video' ? (
          <video
            id="my-video"
            className="video-js media-library__card-video"
            data-setup="{}"
            preload="metadata"
          >
            <source src={`${imgUrl}/media/${media.logo}`} type="video/mp4" />
          </video>
        ) : null}
        <div className="media-library__card-description">
          <span>{media.name}</span>
          <p>{trimString(media.description ?? '', 75)}</p>
          <p>
            {media.type === 'date'
              ? `${moment(media.start).format('MMM, D YYYY')} - 
                                  ${moment(media.end).format('MMM, D YYYY')}`
              : media.type === 'day'
              ? `${media.days[0].day} - ${
                  media.days[media.days.length - 1].day
                }`
              : 'All Day'}
          </p>
        </div>
      </CheckableTag>
      <div className="media-overlay-button-group">
        <Tooltip title="Download">
          <button
            onClick={() => {
              downloadImage(
                `${imgUrl}/media/${media.logo}`,
                media.name.split(' ').join('-'),
                media.fileType
              );
            }}
          >
            <i className="feather-download" />
          </button>
        </Tooltip>
        <Tooltip title="Edit">
          <Link to={`/${siteId}/information/${siteName}/media/${media._id}`}>
            <i className="feather-edit" />
          </Link>
        </Tooltip>
        <Tooltip title="Delete">
          <button onClick={() => onRemoveButtonClickHandler(media._id)}>
            <i className="feather-trash-2" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
});

const PlayListGrid = SortableContainer(
  ({
    mediaList,
    onPreviewModalClickHandler,
    playlist,
    onEditCalendarClickHandler,
    onRemoveButtonClickHandler,
    handleSelectedMedia,
    selectedMediaTags,
  }) => {
    return (
      <div
        className="media-library__card"
      >
        {mediaList.map((media, index) => {
          return (
            <>
              <DragElement
                media={media}
                siteId={media.siteId}
                siteName={media.siteName}
                key={index}
                index={index}
                id={index}
                onPreviewModalClickHandler={onPreviewModalClickHandler}
                onEditCalendarClickHandler={onEditCalendarClickHandler}
                onRemoveButtonClickHandler={onRemoveButtonClickHandler}
                playlist={playlist}
                handleSelectedMedia={handleSelectedMedia}
                selectedMediaTags={selectedMediaTags}
              />
            </>
          );
        })}
      </div>
    );
  }
);

export default PlayListGrid;
