import { Form, Input, Modal, Select } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { informationCleanUp } from 'state/actions/information';
import { regexDescription, regexInput } from 'utils/regex';

import { log } from 'utils';
const { TextArea } = Input;
const { Option } = Select;

const ModalInformationForm = ({
  information,
  setInformation,
  onCreate,
  isEditing,
  onCancel,
  visible,
}) => {
  const { loading, informationCategoriesList } = useSelector(
    (state) => ({
      informationCategoriesList: state.informationCategories.list,
      loading: state.information.loading,
    }),
    shallowEqual
  );

  const [form] = Form.useForm();

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(informationCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setInformation);

  const onChangeSelectHandler = (value, obj) => {
    const categoryId = value;
    const categoryName = obj.children;
    setInformation((prevState) => ({
      ...prevState,
      categoryId,
      categoryName,
    }));
  };

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain a special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain a special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...information,
        id: information._id,
        name: values.name,
        description: values.description,
      });
      form.resetFields();
    }
  };

  return (
    <Modal
      visible={visible}
      title={isEditing ? 'Edit Information' : 'Create New Information'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: information.name,
          description: information.description,
          category: information.categoryName,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: `'Name' is required` }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: `'Description' is required` }]}
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="category"
          label="Information Category"
          rules={[{ required: true }]}
        >
          <Select onChange={onChangeSelectHandler}>
            {informationCategoriesList &&
              informationCategoriesList.map((category) => (
                <Option value={category._id} key={category._id}>
                  {category.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalInformationForm.propTypes = {
  informationData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    siteId: PropTypes.string,
    screenId: PropTypes.string,
    categoryId: PropTypes.string,
    categoryName: PropTypes.string,
    modifiedAt: PropTypes.number,
    modifiedBy: PropTypes.string,
  }),
};

export default ModalInformationForm;
