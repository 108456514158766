import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';

import LanguageWrapper from 'components/LanguageWrapper';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { configureStore } from './state/store';
// import './index.scss';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';
import { GridProvider } from './components/Grid/GridContext';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// import './assets/css/main.css';
import './assets/css/style.css';
// import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
// import 'bulma-switch/dist/css/bulma-switch.min.css';
import 'antd/dist/antd.css';

import { createGlobalStyle } from 'styled-components';

const { width } = window.screen;

const { store, persistor } = configureStore({});
const GlobalStyles = createGlobalStyle`
  html {
    --color-primary: #21486C;
    --color-light-blue: #0371FF;
    --color-secondary: #B1BFCA;
    --color-stroke: #C3CAD9;
    --color-active: #21486C;
    --color-black: #4F4F4F;
    --color-grey: HSL(213, 5%, 39%);
    --color-light-grey: HSL(0, 0%, 76%);
    --color-background: #F7F8FA;
    --color-danger: #ec5b5b;
    --screen-width: ${width - 340}px;
    --color-text-grey: hsla(0, 0%, 48%, 1);
    --color-navbar: #A5A5A5;

    --color-blue-1: hsla(215, 69%, 23%, 1);
    --color-blue-2: hsla(214, 70%, 34%, 1);
    --color-blue-3: hsla(214, 70%, 45%, 1);
    --color-blue-4: hsla(214, 92%, 57%, 1);
    --color-blue-5: hsla(214, 92%, 65%, 1);
    --color-blue-6: hsla(214, 92%, 74%, 1);
    --color-blue-7: hsla(215, 91%, 83%, 1);
    --color-blue-8: hsla(215, 91%, 91%, 1);
  }
`;

const app = (
  <DndProvider backend={HTML5Backend}>
    <GlobalStyles />
    <GridProvider>
      <Provider store={store}>
        <LanguageWrapper>
          <PersistGate persistor={persistor}>
            <ReduxToastr
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              getState={(state) => state.toastr}
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
            <Router />
          </PersistGate>
        </LanguageWrapper>
      </Provider>
    </GridProvider>
  </DndProvider>
);

ReactDOM.render(
    app
  ,document.getElementById('root'));

serviceWorker.unregister();
