import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import {url} from 'utils/url';
import { log } from 'utils';
import { errorMsg } from 'utils';

export const CATEGORIES_FETCH_DATA_INIT = createAction(
  'CATEGORIES_FETCH_DATA_INIT'
);
export const CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'CATEGORIES_FETCH_DATA_SUCCESS'
);
export const CATEGORIES_FETCH_DATA_FAIL = createAction(
  'CATEGORIES_FETCH_DATA_FAIL'
);

export const CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'CATEGORIES_DELETE_CATEGORY_INIT'
);
export const CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'CATEGORIES_DELETE_CATEGORY_FAIL'
);

export const CATEGORIES_CLEAR_DATA = createAction('CATEGORIES_CLEAR_DATA');

export const CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'CATEGORIES_CREATE_CATEGORY_INIT'
);
export const CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'CATEGORIES_CREATE_CATEGORY_FAIL'
);

export const CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'CATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const CATEGORIES_CLEAN_UP = createAction('CATEGORIES_CLEAN_UP');

export const CATEGORIES_CLEAR_DATA_LOGOUT = createAction(
  'CATEGORIES_CLEAR_DATA_LOGOUT'
);

export const fetchCategories = ({ categoryId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_FETCH_DATA_INIT());
    if (categoryId) {
      let categoryData;
      try {
        categoryData = await Axios.get(`${url}/poiCategory/list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      const category = { ...categoryData, id: categoryId };
      return dispatch(
        CATEGORIES_FETCH_DATA_SUCCESS({
          list: getState().categories.list,
          category,
        })
      );
    }

    if (siteId) {
      let categories;
      try {
        categories = await Axios.get(`${url}/poiCategory/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      categories = categories.data.data.filter(
        (category) => category.siteId === siteId
      );

      return dispatch(
        CATEGORIES_FETCH_DATA_SUCCESS({
          list: categories,
          category: getState().categories.category,
        })
      );
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_DELETE_CATEGORY_INIT());

    try {
      await Axios.delete(`${url}/poiCategory/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The category was deleted.');
    return dispatch(CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearCategoriesData = () => {
  return (dispatch) => {
    dispatch(CATEGORIES_CLEAR_DATA());
  };
};

export const clearCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(CATEGORIES_CLEAR_DATA_LOGOUT());
  };
};

export const createCategory = ({
  uid,
  name,
  description,
  siteId,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_CREATE_CATEGORY_INIT());
    let category;
    try {
      category = await Axios.post(
        `${url}/poiCategory/create`,
        {
          name,
          description,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error.response, '<<< error response');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Category created successfully');
    return dispatch(CATEGORIES_CREATE_CATEGORY_SUCCESS({ category: category }));
  };
};

export const modifyCategory = ({
  id,
  name,
  description,
  siteId,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_MODIFY_CATEGORY_INIT());

    const categoryData = {
      id,
      name,
      description,
      siteId,
      modifiedAt,
      modifiedBy,
    };

    const updateCategoryDbTask = Axios.post(
      `${url}/poiCategory/edit`,
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    try {
      await Promise.all([updateCategoryDbTask]);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category updated successfully');

    return dispatch(
      CATEGORIES_MODIFY_CATEGORY_SUCCESS({ category: categoryData, id })
    );
  };
};

export const categoriesCleanUp = () => (dispatch) =>
  dispatch(CATEGORIES_CLEAN_UP());
