/* eslint-disable jsx-a11y/label-has-associated-control */
import { useChangeHandler } from 'hooks';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useParams } from 'react-router-dom';
import { feedbackCleanUp } from 'state/actions/feedback';
import { log } from 'utils';
import _ from 'underscore';
import './FeedbackForm.css';

const FeedbackForm = ({
  feedback,
  setFeedback,
  action,
  days,
  setDays,
  dates,
  setDates,
  history,
  success,
  id,
  errors,
}) => {
  const startRef = useRef();
  const endRef = useRef();
  // const [popupShow, setPopupShow] = useState([]);
  // const [copies, setCopies] = useState([]);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [copyDay, setCopyDay] = useState([]);

  const [error, setError] = useState(null);

  const { siteId, siteName } = useParams();

  const { loading, userData, feedbackList } = useSelector(
    (state) => ({
      loading: state.feedback.loading,
      userData: state.auth.userData,
      feedbackList: state.feedback.list,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  const startDate = feedback.start ? new Date(feedback.start) : undefined;
  const endDate = feedback.end ? new Date(feedback.end) : undefined;

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(feedbackCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setFeedback);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    const filteredFeedbackListStart = _.filter(feedbackList, (item) => {
      return moment().isSameOrBefore(moment(item.start)) || !item.start;
    });

    const filteredFeedbackListEnd = _.filter(feedbackList, (item) => {
      return moment().isSameOrBefore(moment(item.end)) || !item.end;
    });

    const checkDate =
      moment().isSameOrAfter(moment(startDate)) || (!startDate && !endDate);

    const submit = () => {
      dispatch(
        action({
          id,
          uid: uid,
          ...feedback,
          siteId: siteId,
          start: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
          end: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        })
      );
    };
    log(filteredFeedbackListStart, filteredFeedbackListEnd);

    if (checkDate && !id) {
      if (
        filteredFeedbackListStart.length > 2 ||
        filteredFeedbackListEnd.length > 2
      ) {
        toastr.error('Maximum today date is 3');
        setError({
          date: 'Maximum today date is 3',
        });
      } else {
        submit();
      }
    } else {
      submit();
    }
  };

  // const canSubmit = feedback.question;

  const goBackMessage = 'Back';

  const onStartChange = (value) => {
    startRef.current.setOpen(false);
    endRef.current.setOpen(true);
    setFeedback({ ...feedback, start: value, end: undefined });
    setError(null);
    // setEndDate(undefined);
  };

  const onEndDateChange = (value) => {
    setFeedback({ ...feedback, end: value });
    setError(null);
  };

  const DateRangePicker = () => {
    return (
      <div
        className={`range-picker ${
          (error && error.date) || (errors && errors.start && errors.end)
            ? 'has-error'
            : ''
        }`}
      >
        <DatePicker
          className="range-picker-from"
          selected={startDate}
          ref={startRef}
          onChange={onStartChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          // value={startDate}
          placeholderText="From"
        />
        <DatePicker
          ref={endRef}
          className="range-picker-to"
          selected={endDate}
          onChange={(value) => onEndDateChange(value)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          // value={endDate}
          placeholderText="To"
        />
      </div>
    );
  };

  // const handleRemoveFile = () => {
  //   setFeedback({
  //     ...feedback,
  //     file: null,
  //     fileUrl: null,
  //     logo: null,
  //   });
  // };

  const handleReset = () => {
    setFeedback({
      ...feedback,
      end: '',
      question: '',
      siteId: '',
      start: '',
    });
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Feedback Information
              </p>
            </header>
            <div className="card-content">
              <form>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Question <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          id="question"
                          className={`input ${
                            errors && errors.question ? 'is-danger' : ''
                          }`}
                          type="text"
                          required
                          name="question"
                          value={feedback.question}
                          onChange={onChangeHandler}
                          style={{ minHeight: 100 }}
                        />
                      </div>
                      {errors && errors.question && (
                        <p className="help is-danger">
                          {errors.question[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Date <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <DateRangePicker />
                      {error && error.date && (
                        <span className="is-error">{error.date}</span>
                      )}
                      {errors && errors.start && errors.end && (
                        <span className="is-error">This field is required</span>
                      )}
                    </div>
                  </div>
                </div>

                <hr style={{ borderBottom: '1px solid black' }}></hr>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            onClick={onSubmitHandler}
                            type="button"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                            // disabled={!canSubmit}
                          >
                            <span>Submit</span>
                          </button>
                        </div>
                        <div className="control">
                          <Link
                            to={{
                              pathname: `/${siteId}/information/${siteName}`,
                              id: 'feedback',
                            }}
                            className="button"
                          >
                            {goBackMessage}
                          </Link>
                        </div>
                        <button
                          type="reset"
                          className="button"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                Feedback Preview
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">Question</label>
                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={feedback.question}
                  />
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="field">
                <label className="label">Start Date</label>

                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={
                      startDate
                        ? moment(startDate).format('dddd, Do MMMM YYYY')
                        : ''
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">End Date</label>

                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={
                      endDate
                        ? moment(endDate).format('dddd,  Do MMMM YYYY')
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FeedbackForm.propTypes = {
  feedbackData: PropTypes.shape({
    qestion: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    siteId: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  action: PropTypes.func.isRequired,
};

export default FeedbackForm;
