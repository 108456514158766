import {
  DatePicker,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Upload,
  message,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useChangeHandler } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchMediaCategories } from 'state/actions/mediaCategories';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import './MediaForm.css';

function MediaForm({
  media,
  setMedia,
  action,
  days,
  setDays,
  dates,
  setDates,
  history,
  success,
  id,
  error,
}) {
  const [isDragged, setIsDragged] = useState(false);

  const onChangeHandler = useChangeHandler(setMedia);

  // const startRef = useRef();
  // const endRef = useRef();
  const [popupShow, setPopupShow] = useState([]);
  const [copies, setCopies] = useState([]);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [copyDay, setCopyDay] = useState([]);
  const { siteId, siteName } = useParams();
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [errors, setErrors] = useState(error);

  const { loading, userData, categoriesList, mediaList } = useSelector(
    (state) => ({
      mediaList: state.media.list,
      loading: state.media.loading,
      userData: state.auth.userData,
      categoriesList: state.mediaCategories.list,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  const startDate = media.start ? new Date(media.start) : undefined;
  const endDate = media.end ? new Date(media.end) : undefined;
  const type = media.type;

  log(startDate, endDate);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (siteId) {
      dispatch(fetchMediaCategories({ siteId }));
    }
  }, [fetchMediaCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileChangedHandler = (file) => {
    log(file.size / 1000000);
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'video/mp4'
    ) {
      if (file.size / 1000000 <= 50) {
        setMedia((prevState) => ({ ...prevState, file, logo: null }));
      } else {
        message.error('Maximum file size is 50MB');
      }
    } else {
      message.error('Unsupported filetype!');
    }
    setIsDragged(false);
  };

  const onDurationChangeHandler = (value) => {
    setMedia((prevState) => ({
      ...prevState,
      duration: value,
    }));
  };

  const handleDeletePreviewImage = () => {
    setMedia((prevState) => ({ ...prevState, file: null, logo: null }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (
      (!regexInput(media.name) && media.name) ||
      (!regexDescription(media.description) && media.description) ||
      !media.categoryId ||
      (!id && !media.file)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(media.name) &&
        media.name && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      if (!id && !media.file) {
        newErrors.errors.file = [
          {
            msg: 'Upload a media',
          },
        ];
      }
      if (!media.categoryId) {
        newErrors.errors.category = [
          {
            msg: 'Must select category',
          },
        ];
      }

      newErrors.errors.description = !regexDescription(media.description) &&
        media.description && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      setErrors(newErrors);
    } else {
      let daysFilter = [];
      let datesFilter = [];
      if (type === 'day') {
        daysFilter = days
          .filter((x) => x.isActive)
          .map((x) => {
            return {
              day: x.name,
              hours: x.hours.map((hour) => {
                return hour && hour.join('-');
              }),
            };
          });
      } else if (type === 'date') {
        datesFilter = dates
          .filter((x) => x.isActive)
          .map((x) => {
            return {
              date: moment(x.date).format('YYYY-MM-DD'),
              hours: x.hours.map((hour) => {
                return hour.join('-');
              }),
            };
          });
      }

      if (media) {
        const isDuplicateName = mediaList.filter(
          (item) => item.name === media.name
        );

        if (isDuplicateName.length === 0) {
          dispatch(
            action({
              uid,
              ...media,
              siteId,
              start: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
              end: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
              days: daysFilter.filter((z) => z.hours),
              dates: datesFilter.filter((z) => z.hours),
              type,
            })
          );
        } else {
          setIsDuplicate(true);
        }
      }
    }
  };

  const onSelectHandler = (value) => {
    setMedia((prevState) => ({
      ...prevState,
      categoryId: value.value,
      categoryName: value.label,
    }));
  };

  const handleChangeType = (value) => {
    setMedia((prevState) => ({
      ...prevState,
      type: value,
      start: null,
      end: null,
    }));
  };

  const imagePreviewUrl = media.file
    ? media.file && URL.createObjectURL(media.file)
    : media.logo && `${imgUrl}/media/${media.logo}`;

  const handleChangePicker = (value) => {
    const datesOfYear = [];
    for (
      const from = new Date(value[0]);
      from <= value[1];
      from.setDate(from.getDate() + 1)
    ) {
      datesOfYear.push({
        date: new Date(from),
        isActive: true,
        hours: [['', '23.59']],
      });
    }
    setMedia({ ...media, start: value[0], end: value[1] });
    setDates(datesOfYear);
  };

  const handleChangeHour = (name, index, indexAgain, value, type) => {
    const data = type === 'date' ? [...dates] : [...days];

    if (type === 'date' && isApplyToAll) {
      if (name === 'from') {
        const newDate = dates.map((date) => {
          date.hours[indexAgain] = [value, date.hours[indexAgain][1]];
          return {
            ...date,
          };
        });
        setDates(newDate);
      } else {
        const newDate = dates.map((date) => {
          date.hours[indexAgain] = [date.hours[indexAgain][0], value];
          return {
            ...date,
          };
        });
        setDates(newDate);
      }
    } else {
      if (name === 'from') {
        data[index].hours[indexAgain] = [
          value,
          data[index].hours[indexAgain][1],
        ];
      } else {
        data[index].hours[indexAgain] = [
          data[index].hours[indexAgain][0],
          value,
        ];
      }
      type === 'date' && setDates(data);
      type === 'day' && setDays(data);
    }
  };

  const handleChangeDate = (index, checked) => {
    if (isApplyToAll) {
      const newDates = dates.map((date) => {
        return {
          ...date,
          isActive: checked,
        };
      });

      setDates(newDates);
    } else {
      const newDates = [...dates];
      newDates[index].isActive = checked;
      setDates(newDates);
    }
  };

  const handleChangeDay = (index, checked) => {
    const newDays = [...days];
    newDays[index].isActive = checked;
    setDays(newDays);
  };

  const handleDeleteHour = (index, indexAgain) => {
    if (isApplyToAll) {
      const filterHour = dates.map((date) => {
        return {
          ...date,
          hours: date.hours.filter((_, index) => index !== indexAgain),
        };
      });

      setDates(filterHour);
    } else {
      const newDate = [...dates];
      const filterHour = newDate[index].hours.filter(
        (_, index) => index !== indexAgain
      );

      newDate[index].hours = filterHour;
      setDates(newDate);
    }
  };

  const handleDeleteHourDay = (index, indexAgain) => {
    const newDay = [...days];
    const filterHour = newDay[index].hours.filter(
      (_, index) => index !== indexAgain
    );

    newDay[index].hours = filterHour;
    setDays(newDay);
  };

  const handleAddHour = (index) => {
    if (isApplyToAll) {
      const newDate = dates.map((date) => {
        date.hours.push(['', '23.59']);
        return {
          ...date,
        };
      });

      setDates(newDate);
    } else {
      const newDate = [...dates];
      newDate[index].hours.push(['', '']);
      setDates(newDate);
    }
  };

  const handleAddHourDay = (index) => {
    const newDay = [...days];
    newDay[index].hours.push(['', '23.59']);
    setDays(newDay);
  };

  const handleShowPopUp = (selectedIndex) => {
    const newPopupShow = [...popupShow];
    newPopupShow[selectedIndex] = !newPopupShow[selectedIndex];
    setPopupShow(newPopupShow);
  };

  const handleCopy = (checked, selectedDate) => {
    const findFirst = copies[selectedDate];
    const date = dates[selectedDate];

    if (checked) {
      if (!findFirst) {
        setCopies([...copies, date]);
      }
    } else {
      const filterChecked = copies.filter((_, index) => index !== selectedDate);
      setCopies(filterChecked);
    }
  };

  const handleCopyDay = (checked, selectedDay) => {
    const findFirst = copyDay[selectedDay];
    const day = days[selectedDay];

    if (checked) {
      if (!findFirst) {
        setCopyDay([...copyDay, day]);
      }
    } else {
      const filterChecked = copyDay.filter((_, index) => index !== selectedDay);

      setCopyDay(filterChecked);
    }
  };

  const handleClickApply = (value) => {
    const dateHours = value;

    const newDate = [...dates];
    copies &&
      // eslint-disable-next-line
      copies.map((copy) => {
        const findDate = newDate.find((date) =>
          moment(date.date).isSame(copy.date)
        );
        findDate.hours = [...dateHours];
      });
    setDates(newDate);
    setPopupShow([]);
  };

  const handleClickApplyDay = (value) => {
    const dayHours = value;

    const newDay = [...days];
    // eslint-disable-next-line
    copyDay.map((copy) => {
      const findDay = newDay.find((day) => day.alias === copy.alias);
      findDay.hours = [...dayHours];
    });

    setDays(newDay);
    setPopupShow([]);
  };

  const timeInput = (indexAgain, index, type, hourFrom, hourTo) => (
    <div>
      <InputMask
        mask="99:99"
        placeholder="00:00"
        type="text"
        className="hour-input"
        value={hourFrom}
        onChange={(e) =>
          handleChangeHour('from', index, indexAgain, e.target.value, type)
        }
      />
      <span>-</span>
      <InputMask
        mask="99:99"
        placeholder="00:00"
        type="text"
        className="hour-input"
        value={hourTo}
        onChange={(e) =>
          handleChangeHour('to', index, indexAgain, e.target.value, type)
        }
      />
    </div>
  );

  const handleClickApplyToAll = (e) => {
    setIsApplyToAll(e.target.checked);
  };

  const handleReset = () => {
    setMedia({
      ...media,
      dates: [],
      days: [],
      description: '',
      duration: '',
      end: '',
      fileType: '',
      fileUrl: null,
      modifiedBy: '',
      name: '',
      siteId: '',
      start: '',
      type: 'all',
    });
  };

  return (
    <div className="media-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Media Name: <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Media Name"
              name="name"
              type="text"
              value={media.name}
              onChange={onChangeHandler}
            />

            {isDuplicate && (
              <span className="error-message">Name already exist!</span>
            )}

            {errors &&
              errors.errors &&
              errors.errors.name &&
              errors.errors.name.map((name, idx) => (
                <div className="error-message" key={idx}>
                  {name.msg}
                </div>
              ))}
          </div>
          <div className="form">
            <label className="form-label">
              Category: <span className="is-required">*</span>
            </label>
            <Select
              labelInValue
              value={
                media.categoryId
                  ? {
                      value: media.categoryId,
                      label:
                        categoriesList.length &&
                        categoriesList.find(
                          (category) => category._id === media.categoryId
                        ).name,
                    }
                  : null
              }
              onChange={onSelectHandler}
              placeholder="Select Category"
            >
              {categoriesList &&
                categoriesList
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )
                  .map((category) => (
                    <Select.Option value={category._id} key={category._id}>
                      {category.name}
                    </Select.Option>
                  ))}
            </Select>

            {errors &&
              errors.errors &&
              errors.errors.category &&
              errors.errors.category.map((category, idx) => (
                <div className="error-message" key={idx}>
                  {category.msg}
                </div>
              ))}
          </div>
          <div className="form-group">
            <div className="form">
              <label className="form-label">
                Color <span className="is-required">*</span>
              </label>
              <input
                style={{ backgroundColor: media.color }}
                onChange={onChangeHandler}
                name="color"
                value={media.color}
                type="color"
              />
            </div>
            <div className="form">
              <label className="form-label">
                Duration <span className="is-required">*</span>
              </label>
              <div className="input-group">
                {/* <TimePicker
                  onChange={onDurationChangeHandler}
                  format={format}
                  value={moment(
                    `${media.duration ? media.duration : '00'}`,
                    format
                  )}
                /> */}
                <InputNumber
                  onChange={onDurationChangeHandler}
                  value={media.duration}
                />
                <div>Seconds</div>
              </div>

              {errors &&
                errors.errors &&
                errors.errors.duration &&
                errors.errors.duration.map((duration, idx) => (
                  <div className="error-message" key={idx}>
                    {duration.msg}
                  </div>
                ))}
            </div>
          </div>
          <div className="form">
            <label className="form-label">
              Media <span className="is-required">*</span>
            </label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                {(media.file && media.file.type === 'video/mp4') ||
                (media.fileType === 'video/mp4' && !media.file) ? (
                  <video
                    controls="controls"
                    preload="metadata"
                    key={imagePreviewUrl}
                  >
                    <source src={imagePreviewUrl} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className="media-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                )}
              </div>
            ) : (
              <Upload
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload (Max file size 50mb)</p>
                </div>
              </Upload>
            )}
            {errors &&
              errors.errors &&
              errors.errors.file &&
              errors.errors.file.map((file, idx) => (
                <div className="error-message" key={idx}>
                  {file.msg}
                </div>
              ))}
          </div>
          <div className="form">
            <label className="form-label">Description </label>
            <Input.TextArea
              placeholder="Insert Description"
              name="description"
              value={media.description}
              onChange={onChangeHandler}
              rows={4}
            />
            {errors &&
              errors.errors &&
              errors.errors.description &&
              errors.errors.description.map((description, idx) => (
                <div className="error-message" key={idx}>
                  {description.msg}
                </div>
              ))}
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">
              Scheduling: <span className="is-required">*</span>
            </label>
            <Select
              onChange={(value) => handleChangeType(value)}
              value={type}
              defaultValue="all"
            >
              <Select.Option value="all">All Day</Select.Option>
              <Select.Option value="day">Day</Select.Option>
              <Select.Option value="date">Date</Select.Option>
            </Select>
          </div>

          {type === 'date' && (
            <div className="form">
              <label className="form-label">
                Date <span className="is-required">*</span>
              </label>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                onChange={handleChangePicker}
              />
            </div>
          )}

          {type === 'day' && (
            <div className="card-adsign">
              <div className="card-title">
                <p>
                  Set Hours <span className="is-required">*</span>
                </p>
              </div>
              {days.map((day, index) => {
                return (
                  <div className="card-adsign-content" key={index}>
                    <div className="card-checkbox">
                      <Checkbox
                        checked={day.isActive}
                        onChange={(e) =>
                          handleChangeDay(index, e.target.checked)
                        }
                        id={`day-${index}`}
                      >
                        {day.alias}
                      </Checkbox>
                    </div>
                    <div className="hour-wrapper">
                      {day.hours &&
                        day.hours.map((hour, indexAgain) => {
                          const hourFrom = hour[0] ? hour[0] : '';
                          const hourTo = hour[1] ? hour[1] : '';
                          return (
                            <div key={indexAgain}>
                              {timeInput(
                                indexAgain,
                                index,
                                type,
                                hourFrom,
                                hourTo
                              )}
                              <button
                                className="button-link"
                                type="button"
                                onClick={() =>
                                  handleDeleteHourDay(index, indexAgain)
                                }
                              >
                                <i className="feather-trash-2" />
                              </button>
                            </div>
                          );
                        })}
                    </div>
                    <div className="card-button-wrapper">
                      <button
                        className="button-link"
                        onClick={() => handleAddHourDay(index)}
                        type="button"
                      >
                        <i className="feather-plus" />
                      </button>
                      <button
                        onClick={() => handleShowPopUp(index)}
                        className="button-link"
                        type="button"
                      >
                        <i className="feather-copy" />
                      </button>
                      {popupShow && popupShow[index] && (
                        <div className="copy-popup">
                          <p>Copy times to...</p>
                          {days.map((day, indexAgain) => {
                            return (
                              <div key={indexAgain}>
                                <Checkbox
                                  defaultChecked={indexAgain === index}
                                  id={`copy-day-${indexAgain}`}
                                  onChange={(e) =>
                                    handleCopyDay(e.target.checked, indexAgain)
                                  }
                                >
                                  {day.name}
                                </Checkbox>
                              </div>
                            );
                          })}

                          <button
                            onClick={() => handleClickApplyDay(day.hours)}
                            className="button-blue"
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {type === 'date' && startDate && endDate && (
            <div className="card-adsign">
              <div className="card-title">
                <p>
                  Set Hours <span className="is-required">*</span>
                </p>
                <div className="card-option">
                  <Checkbox
                    checked={isApplyToAll}
                    onChange={handleClickApplyToAll}
                  >
                    Apply to all
                  </Checkbox>
                </div>
              </div>
              {dates.map((date, index) => {
                return (
                  <div className="card-adsign-content" key={index}>
                    <div className="card-checkbox">
                      <Checkbox
                        checked={date.isActive}
                        onChange={(e) =>
                          handleChangeDate(index, e.target.checked)
                        }
                        id={`date-${index}`}
                      >
                        {moment(date.date).format('MM-DD')}
                      </Checkbox>
                    </div>
                    <div className="hour-wrapper">
                      {date.hours &&
                        date.hours.map((hour, indexAgain) => {
                          const hourFrom = hour[0] ? hour[0] : '';
                          const hourTo = hour[1] ? hour[1] : '';
                          return (
                            <div key={indexAgain}>
                              <div>
                                <InputMask
                                  mask="99:99"
                                  placeholder="00:00"
                                  type="text"
                                  className="hour-input"
                                  value={hourFrom}
                                  onChange={(e) =>
                                    handleChangeHour(
                                      'from',
                                      index,
                                      indexAgain,
                                      e.target.value,
                                      type
                                    )
                                  }
                                />
                                <span>-</span>
                                <InputMask
                                  mask="99:99"
                                  placeholder="00:00"
                                  type="text"
                                  className="hour-input"
                                  value={hourTo}
                                  onChange={(e) =>
                                    handleChangeHour(
                                      'to',
                                      index,
                                      indexAgain,
                                      e.target.value,
                                      type
                                    )
                                  }
                                />
                              </div>
                              <button
                                className="button-link"
                                type="button"
                                onClick={() =>
                                  handleDeleteHour(index, indexAgain)
                                }
                              >
                                <i className="feather-trash-2" />
                              </button>
                            </div>
                          );
                        })}
                    </div>
                    <div className="card-button-wrapper">
                      <button
                        className="button-link"
                        onClick={() => handleAddHour(index)}
                        type="button"
                      >
                        <i className="feather-plus" />
                      </button>
                      <button
                        onClick={() => handleShowPopUp(index)}
                        className="button-link"
                        type="button"
                      >
                        <i className="feather-copy" />
                      </button>
                      {popupShow && popupShow[index] && (
                        <div className="copy-popup">
                          <p>Copy times to...</p>
                          {dates.map((date, indexAgain) => {
                            return (
                              <div key={indexAgain}>
                                <Checkbox
                                  defaultChecked={indexAgain === index}
                                  id={`copy-day-${indexAgain}`}
                                  onChange={(e) =>
                                    handleCopy(e.target.checked, indexAgain)
                                  }
                                >
                                  {moment(date.date).format('YYYY-MM-DD')}
                                </Checkbox>
                              </div>
                            );
                          })}

                          <button
                            onClick={() => handleClickApply(date.hours)}
                            className="button-blue"
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </form>
      <div className="button-wrapper">
        {loading ? (
          <button type="button" disabled className="button-primary">
            Loading...
          </button>
        ) : (
          <button
            type="button"
            className="button-primary"
            onClick={onSubmitHandler}
          >
            Save
          </button>
        )}
        <button
          type="submit"
          className="button-secondary"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default MediaForm;
