import { Breadcrumb, Table, Tooltip } from 'antd';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchUsers, unlockUser } from 'state/actions/users';
import { fetchRoles } from 'state/actions/roles';
import { regexInput } from 'utils/regex';
// import './User.css';
import './PanelUser.scss';

function PanelUser() {
  const [search, setSearch] = useState('');

  const {
    usersList,
    // isAdmin,
    error,
    loading,
    deleted,
    userData,
    role,
    roles,
    unlock,
  } = useSelector(
    (state) => ({
      usersList: state.users.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
      userData: state.auth.userData,
      roles: state.roles.list,
      role: state.auth.userData.role,
      unlock: state.users.unlock,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchUsers());
      dispatch(fetchRoles());
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (deleted && !loading) {
      dispatch(fetchUsers());
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (unlock) {
      dispatch(fetchUsers());
    }
  }, [unlock]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchError, setSearchError] = useState(null);

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };

  const onUnlockUser = (id) => {
    dispatch(unlockUser(id));
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: useFormatMessage('Users.name'),
      dataIndex: 'name',
    },
    {
      title: useFormatMessage('Users.email'),
      dataIndex: 'email',
    },
    {
      title: useFormatMessage('Users.role'),
      dataIndex: 'roleAccess',

      render: (text, record) => {
        const roleUser = roles.find((r) => r._id === record.roleId);
        if (record.roleId && roleUser) {
          return <div>{roleUser.name}</div>;
        }
        return <span>-</span>;
      },
    },
    {
      title: 'User Status',
      dataIndex: 'status',

      render: (text, record) => {
        if (record.isLockedOut) {
          return (
            <div>
              <div>{'Locked Out'}</div>
              <div>
                <Tooltip title="Unlock user">
                  <div
                    onClick={() => onUnlockUser(record.email)}
                    className="btn btn--edit"
                  >
                    <i className="feather-unlock" />
                    Unlock
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        }
        return <span>{'Active'}</span>;
      },
    },
    {
      title: 'Password Expired Date',
      dateIndex: 'lastPasswordChangedDate',

      render: (text, record) => {
        const roleUser = roles.find((r) => r._id === record.roleId);
        if (record.lastLoginDate && roleUser) {
          return (
            <div>{`${moment(record.lastPasswordChangedDate)
              .utcOffset('+08:00')
              .add(roleUser.expirationDuration, 'days')
              .format('h:mm A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
  ];

  const data = search
    ? usersList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        const name =
          clonedElem.name &&
          clonedElem.name.toLowerCase().includes(search.toLowerCase());
        const org =
          clonedElem.organizationName &&
          clonedElem.organizationName
            .toLowerCase()
            .includes(search.toLowerCase());
        const email =
          clonedElem.email &&
          clonedElem.email.toLowerCase().includes(search.toLowerCase());
        const role =
          clonedElem.role &&
          clonedElem.role.toLowerCase().includes(search.toLowerCase());
        if (name || org || email || role) {
          return clonedElem;
        }
        return false;
      })
    : // eslint-disable-next-line
      usersList.filter((el) => {
        if (role === 'superadmin') {
          return el.role !== 'superadmin';
        } else if (role === 'admin') {
          return (
            el.role !== 'superadmin' &&
            el.organizationName === userData.organizationName
          );
        }
      });

  return (
    <section className="panel-user">
      {redirect}
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Users
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Users</h1>
        </div>
        <div className="header-right-content">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search"></i>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            {searchError && <div className="search-error">{searchError}</div>}
          </div>
        </div>
      </div>

      <div className="panel-user__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}

export default PanelUser;
