import {
  Breadcrumb,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Modal,
  Select,
} from 'antd';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  createRole,
  deleteRole,
  fetchRoles,
  modifyRole,
} from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import _ from 'underscore';
import RolesTable from './RolesTable';
import './Users.scss';

function Role() {
  const [search, setSearch] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('All');
  const [data, setData] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [form, setForm] = useState({
    id: '',
    name: '',
    access: [],
  });

  const {
    roleList,
    loading,
    role,
    sites,
    organizations,
    success,
    created,
  } = useSelector(
    (state) => ({
      roleList: state.roles.list,
      userList: state.users.list,
      // isAdmin: state.auth.userData.isAdmin,
      error: state.roles.error,
      loading: state.roles.loading,
      deleted: state.roles.deleted,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      sites: state.sites.list,
      organizations: state.organizations.list,
      success: state.roles.success,
      created: state.roles.created,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  console.log(sites, 'sites');
  console.log(roleList, 'roleList');

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      console.log('get user');
      dispatch(fetchUsers());
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (success && !loading) {
      setIsModalShow(false);
      setForm({
        id: '',
        name: '',
        access: [],
      });
    }
  }, [success, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('get role');
    dispatch(fetchRoles());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (created) {
      dispatch(fetchRoles());
    }
  }, [created]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sites) {
      setData([
        ...data,
        ..._.sortBy(sites, 'name').map((site) => {
          return {
            id: site._id,
            title: site.name,
            key: site._id,
            site_id: site._id,
            organization_id: site.organizationId,
            children: [
              {
                key: `poi-${site._id}`,
                organization_id: site.organizationId,
                id: `poi-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'POI',
                module: 'poi',
                children: [
                  {
                    key: `read-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Read POI',
                    type: 'read',
                    module: 'poi',
                  },
                  {
                    key: `create-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Create POI',
                    type: 'create',
                    module: 'poi',
                  },
                  {
                    key: `update-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Update POI',
                    type: 'update',
                    module: 'poi',
                  },
                  {
                    key: `delete-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Delete POI',
                    type: 'delete',
                    module: 'poi',
                  },
                ],
              },
              {
                key: `media-${site._id}`,
                organization_id: site.organizationId,
                id: `media-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Media',
                module: 'media',
                children: [
                  {
                    key: `read-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Read Media',
                    type: 'read',
                    module: 'media',
                  },
                  {
                    key: `create-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Create Media',
                    type: 'create',
                    module: 'media',
                  },
                  {
                    key: `update-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Update Media',
                    type: 'update',
                    module: 'media',
                  },
                  {
                    key: `delete-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Media',
                    type: 'delete',
                    module: 'media',
                  },
                ],
              },
              {
                key: `information-${site._id}`,
                organization_id: site.organizationId,
                id: `information-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Information',
                module: 'information',
                children: [
                  {
                    key: `read-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Read Information',
                    type: 'read',
                    module: 'information',
                  },
                  {
                    key: `create-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Create Information',
                    type: 'create',
                    module: 'information',
                  },
                  {
                    key: `update-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Update Information',
                    type: 'update',
                    module: 'information',
                  },
                  {
                    key: `delete-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Information',
                    type: 'delete',
                    module: 'information',
                  },
                ],
              },
              {
                key: `feedback-${site._id}`,
                organization_id: site.organizationId,
                id: `feedback-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Feedback',
                module: 'feedback',
                children: [
                  {
                    key: `read-feedback-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-feedback-${site._id}`,
                    parent_id: `feedback-${site._id}`,
                    site_id: site._id,
                    title: 'Read Feedback',
                    type: 'read',
                    module: 'feedback',
                  },
                  {
                    key: `create-feedback-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-feedback-${site._id}`,
                    parent_id: `feedback-${site._id}`,
                    site_id: site._id,
                    title: 'Create Feedback',
                    type: 'create',
                    module: 'feedback',
                  },
                  {
                    key: `update-feedback-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-feedback-${site._id}`,
                    parent_id: `feedback-${site._id}`,
                    site_id: site._id,
                    title: 'Update Feedback',
                    type: 'update',
                    module: 'feedback',
                  },
                  {
                    key: `delete-feedback-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-feedback-${site._id}`,
                    parent_id: `feedback-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Feedback',
                    type: 'delete',
                    module: 'feedback',
                  },
                ],
              },
              {
                key: `map-${site._id}`,
                organization_id: site.organizationId,
                id: `map-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Maps',
                module: 'map',
                children: [
                  {
                    key: `read-map-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-map-${site._id}`,
                    parent_id: `map-${site._id}`,
                    site_id: site._id,
                    title: 'Read Maps',
                    type: 'read',
                    module: 'map',
                  },
                  {
                    key: `create-map-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-map-${site._id}`,
                    parent_id: `map-${site._id}`,
                    site_id: site._id,
                    title: 'Create Maps',
                    type: 'create',
                    module: 'map',
                  },
                  {
                    key: `update-map-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-map-${site._id}`,
                    parent_id: `map-${site._id}`,
                    site_id: site._id,
                    title: 'Update Maps',
                    type: 'update',
                    module: 'map',
                  },
                  {
                    key: `delete-map-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-map-${site._id}`,
                    parent_id: `map-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Maps',
                    type: 'delete',
                    module: 'map',
                  },
                ],
              },
            ],
          };
        }),
      ]);
    }
  }, [sites]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: () => (
        <div className="search-permission">
          <input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Start typing site name"
            className="search-permission-input"
          />
          <i className="feather-search icon-search-permission" />
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
      width: 300,
      fixed: 'left',
    },
  ];

  const columnsPermission = roleList
    ? [
        ...columns,
        ...roleList.map((role) => {
          return {
            title: () => (
              <div className="permission-title">
                <div className="permission-title-content">
                  <p className="permission-title-text">Name</p>
                  <p className="permission-subtitle-text">{role.name}</p>
                </div>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="0" onClick={() => handleEdit(role._id)}>
                        <i className="feather-edit mr-1/5" />
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        key="1"
                        onClick={() => onRemoveButtonClickHandler(role._id)}
                      >
                        <i className="feather-delete mr-1/5" />
                        Delete
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <button className="permission-menu">
                    <i className="feather-more-vertical" />
                  </button>
                </Dropdown>
              </div>
            ),
            dataIndex: 'site_id',
            key: 'site_id',
            align: 'center',
            render: (site_id, record) => {
              const findAccess =
                role.access &&
                role.access.find((access) => access.siteId === site_id);

              if (record.module) {
                if (record.type) {
                  const allow = findAccess
                    ? findAccess[record.module][record.type]
                    : false;
                  return (
                    <Checkbox
                      onChange={(e) =>
                        handleAllow(
                          role._id,
                          site_id,
                          record.module,
                          record.type,
                          e.target.checked
                        )
                      }
                      checked={allow}
                    />
                  );
                } else {
                  const countChildren = record.children;
                  const totalChildren = findAccess
                    ? _.flatten(
                        countChildren.map((x) => findAccess[x.module][x.type])
                      )
                    : [];

                  const howManyTrueChildren = totalChildren.filter((x) => x);

                  return (
                    <Checkbox
                      onChange={(e) =>
                        handleAllowSome(
                          role._id,
                          site_id,
                          record.module,
                          e.target.checked
                        )
                      }
                      indeterminate={
                        howManyTrueChildren.length !== totalChildren.length &&
                        howManyTrueChildren.length > 0
                      }
                      checked={
                        howManyTrueChildren.length === totalChildren.length &&
                        findAccess
                      }
                    />
                  );
                }
              } else {
                const countChildren = record.children;
                const totalChildren = findAccess
                  ? _.flatten(
                      countChildren.map((x) =>
                        x.children.map((x) => findAccess[x.module][x.type])
                      )
                    )
                  : [];

                const howManyTrueChildren = totalChildren.filter((x) => x);

                return (
                  <Checkbox
                    onChange={(e) =>
                      handlAllowAll(role._id, site_id, e.target.checked)
                    }
                    indeterminate={
                      howManyTrueChildren.length !== totalChildren.length &&
                      howManyTrueChildren.length > 0
                    }
                    checked={
                      howManyTrueChildren.length === totalChildren.length &&
                      findAccess
                    }
                  />
                );
              }
            },
          };
        }),
      ]
    : columns;

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleChangeOrg = (value) => {
    setSelectedOrganization(value);
  };

  const handleAllow = (id, siteId, module, type, checked) => {
    const findRoleList = [...roleList].find((x) => x._id === id);
    const findAccess = [...findRoleList.access].find(
      (x) => x.siteId === siteId
    );
    if (findAccess) {
      if (findAccess[module]) {
        findAccess[module][type] = checked;
        console.log(findRoleList);
        dispatch(modifyRole(findRoleList, roleList));
      }
    } else {
      const allows = {
        // api: {
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        poi: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        media: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        information: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        feedback: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        map: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      };

      allows[module][type] = checked;
      allows.siteId = siteId;

      const newAccess = {
        ...findRoleList,
        access: findRoleList.access
          ? [...findRoleList.access, allows]
          : [allows],
      };
      console.log(roleList, 'roleList');
      dispatch(modifyRole(newAccess, roleList));
    }
  };

  const handleAllowSome = (id, siteId, module, checked) => {
    const findRoleList = [...roleList].find((x) => x._id === id);
    const findAccess = [...findRoleList.access].find(
      (x) => x.siteId === siteId
    );

    if (findAccess) {
      findAccess[module].create = checked;
      findAccess[module].delete = checked;
      findAccess[module].read = checked;
      findAccess[module].update = checked;

      //   console.log(findRoleList);
      dispatch(modifyRole(findRoleList, roleList));
    } else {
      const allows = {
        // api: {
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        poi: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        media: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        information: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        feedback: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        map: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      };

      allows[module].create = checked;
      allows[module].delete = checked;
      allows[module].read = checked;
      allows[module].update = checked;
      allows.siteId = siteId;

      const newAccess = {
        ...findRoleList,
        access: findRoleList.access
          ? [...findRoleList.access, allows]
          : [allows],
      };

      //   console.log(newAccess, 'newAccess');
      dispatch(modifyRole(newAccess, roleList));
    }
  };

  const handlAllowAll = (id, siteId, checked) => {
    const findRoleList = [...roleList].find((x) => x._id === id);
    const findAccess = [...findRoleList.access].find(
      (x) => x.siteId === siteId
    );

    if (findAccess) {
      const accessMap = Object.keys(findAccess);
      accessMap.map((x) => {
        if (x !== 'siteId') {
          findAccess[x].create = checked;
          findAccess[x].delete = checked;
          findAccess[x].read = checked;
          findAccess[x].update = checked;
        }
        return null;
      });

      dispatch(modifyRole(findRoleList, roleList));
    } else {
      const allows = {
        // api: {
        //   create: checked,
        //   read: checked,
        //   update: checked,
        //   delete: checked,
        // },
        poi: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        media: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        information: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        feedback: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        map: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        siteId,
      };

      const newAccess = {
        ...findRoleList,
        access: findRoleList.access
          ? [...findRoleList.access, allows]
          : [allows],
      };
      dispatch(modifyRole(newAccess, roleList));
    }
  };

  const handleChangeInput = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (form.id) {
      dispatch(modifyRole(form, roleList));
    } else {
      dispatch(createRole(form));
    }
  };

  const handleEdit = (id) => {
    const findRole = roleList.find((x) => x._id === id);
    setForm({
      ...form,
      ...findRole,
      id,
    });
    setIsModalShow(true);
  };

  const handleCloseModal = () => {
    setForm({
      id: '',
      name: '',
      access: [],
    });
    setIsModalShow(false);
  };

  const onRemoveButtonClickHandler = (roleId) => {
    Modal.confirm({
      title: 'Are you sure want to delete this role?',
      content: 'Data cannot be restored after delete',
      onOk: () => dispatch(deleteRole(roleId)),
      okButtonProps: {
        loading,
      },
    });
  };

  return (
    <section className="roles">
      {redirect}
      <Modal
        title={form.id ? 'Edit Role' : 'Add new Role'}
        visible={isModalShow}
        onCancel={handleCloseModal}
        okButtonProps={{
          loading,
        }}
        okText={form.id ? 'Save Changes' : 'Save'}
        onOk={handleSubmit}
      >
        <label style={{ marginBottom: '0.5em' }}>
          Role Name <span className="is-required">*</span>
        </label>
        <Input
          onChange={(e) => handleChangeInput('name', e.target.value)}
          required
          placeholder="eg: Administrator"
          value={form.name}
        />
      </Modal>
      <div className="header-content">
        <div className="header-left-content">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-active">
                Roles
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1 className="page-title">Roles</h1>
        </div>
      </div>
      <div className="roles__table">
        <div className="header-content">
          <div className="left-content">
            <label>Select Organization</label>
            <Select defaultValue="All" onChange={handleChangeOrg}>
              <Select.Option value="All">All Organization</Select.Option>
              {organizations &&
                organizations.map((organization) => {
                  return (
                    <Select.Option
                      value={organization._id}
                      key={organization._id}
                    >
                      {organization.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <button
            className="button-primary"
            onClick={() => setIsModalShow(true)}
          >
            <i className="feather-plus" />
            Add Roles
          </button>
        </div>
        <div className="table">
          <RolesTable
            data={data}
            columns={columnsPermission}
            search={search}
            selectedOrganization={selectedOrganization}
            loading={loading}
          />
        </div>
      </div>
    </section>
  );
}

export default Role;
