import { createReducer } from 'redux-act';
import moment from 'moment';

import {
  INFORMATION_FETCH_DATA_INIT,
  INFORMATION_FETCH_DATA_SUCCESS,
  INFORMATION_FETCH_DATA_FAIL,
  INFORMATION_DELETE_INIT,
  INFORMATION_DELETE_SUCCESS,
  INFORMATION_DELETE_FAIL,
  INFORMATION_CLEAR_DATA,
  INFORMATION_CREATE_INIT,
  INFORMATION_CREATE_SUCCESS,
  INFORMATION_CREATE_FAIL,
  INFORMATION_MODIFY_INIT,
  INFORMATION_MODIFY_SUCCESS,
  INFORMATION_MODIFY_FAIL,
  INFORMATION_CLEAN_UP,
  INFORMATION_CLEAR_DATA_LOGOUT,
} from 'state/actions/information';

const initialState = {
  list: [],
  information: {
    name: '',
    description: '',
    siteId: '',
    screenId: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const informationReducer = createReducer(
  {
    [INFORMATION_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [INFORMATION_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      information: payload.information || initialState.information,
      loading: false,
      error: null,
    }),
    [INFORMATION_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_DELETE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INFORMATION_DELETE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [INFORMATION_DELETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [INFORMATION_CREATE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INFORMATION_CREATE_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.information),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [INFORMATION_CREATE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INFORMATION_MODIFY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.information.name,
                description: payload.information.description,
                siteId: payload.information.siteId,
                screenId: payload.information.screenId,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.information.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [INFORMATION_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [INFORMATION_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [INFORMATION_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
