import { Form, Modal, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { floorCleanUp } from 'state/actions/files';
import { log } from 'utils';
import './ModalFileManagement.css'

export default function ModalFileManagementForm({
  setFile,
  visible,
  loading,
  onCancel,
  onCreate,
  file
}) {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    file: ''
  });
  const [isDragged, setIsDragged] = useState(false);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => dispatch(floorCleanUp());
  // }, [dispatch]);
  const onChangeHandler = useChangeHandler(setFile);

  const onSave = (values) => {
   
      setErrors({
        name: '',
        description: '',
      });
      onCreate();
      form.resetFields();
    
  };
  const onFileChangedHandler = (file) => {
    setFile({file});
  };

  const handleDeletePreviewImage = () => {
    setFile(null);
  };
  return (
    <Modal
      visible={visible}
      title= 'Upload New IPK File'
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed', info);
          });
      }}
    >
      <div className="file-form">
      <Form
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          file:null,
        }}
      >
        <Form.Item
          label="IPK File"
          name="file"
          rules={[{ required: true }]}
          validateStatus={errors.file ? 'error' : 'success'}
          help={errors.file ? errors.file : null}
        >
          {file ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <span>
                  {file.file.name}
                </span>
                {/* <span
                  className="media-avatar"
                  src={file}
                  alt={file.name}
                /> */}
              </div>
            ) : (
              <Upload
                accept="application/vnd.shana.informed.package"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <i className="feather-upload" />
                  <p>Drag or click to upload</p>
                </div>
              </Upload>
            )}
        </Form.Item>
      </Form>
      </div>
    </Modal>
  );
}


