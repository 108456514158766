import { Tabs, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Categories from 'pages/Categories';
// import Feedbacks from 'pages/Feedbacks';
import Floors from 'pages/Floors';
import Information from 'pages/Information';
import InformationCategories from 'pages/InformationCategories';
import Maps from 'pages/Maps';
import MediaCategories from 'pages/MediaCategories';
import MediaLibrary from 'pages/MediaLibrary';
import Pois from 'pages/Pois';
import { log } from 'utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setActiveTab } from 'state/actions/tab';
import './SiteInfo.css';
import ScreenSettings from 'pages/ScreenSettings';
import ConfigVariable from 'pages/ConfigVariable';
import ScreenConfiguration from 'pages/ScreenConfiguration';
import FileManagement from 'pages/FileManagement';
import Screens from 'pages/Screens';

const { TabPane } = Tabs;

function SiteInfo({ history, setCollapsed }) {
  const { siteId, siteName } = useParams();
  const [selected, setSelected] = useState();
  const [grantAccess, setGrantAccess] = useState();

  const dispatch = useDispatch();
  const { tab: activeTab, userData, sitesList } = useSelector((state) => ({
    tab: state.tab.tab,
    userData: state.auth.userData,
    sitesList: state.sites.list,
  }));

  useEffect(() => {
    const acc =
      userData.role !== 'superadmin' && userData.roleAccess
        ? userData.roleAccess.access.find(
            (userAccess) => userAccess.siteId === siteId
          )
        : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
          userData.access[0];
    console.log(userData.role);
    setGrantAccess(acc ?? userData.access[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (selected) {
      dispatch(setActiveTab({ tab: selected }));
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    setActiveTab(activeKey);
    dispatch(setActiveTab({ tab: activeKey }));
  };

  const siteDescription =
    sitesList &&
    // eslint-disable-next-line
    sitesList.map((site) => {
      if (site.name === siteName) {
        return site.description;
      }
    });

  const media = (
    <Menu onClick={(e) => setSelected(e.key)}>
      <Menu.Item key="media">
        <div>
          <i className="feather-image" /> Media List
        </div>
      </Menu.Item>
      <Menu.Item key="mediaCategory">
        <div>
          <i className="feather-list" /> Media Categories
        </div>
      </Menu.Item>
    </Menu>
  );

  const poi = (
    <Menu onClick={(e) => setSelected(e.key)} style={{ minWidth: 200 }}>
      <Menu.Item key="poi">
        <div>
          <i className="feather-box" /> Poi List
        </div>
      </Menu.Item>
      <Menu.Item key="poiCategory">
        <div>
          <i className="feather-list" /> Poi Categories
        </div>
      </Menu.Item>
      <Menu.Item key="floor">
        <div>
          <i className="feather-map-pin" /> Floor
        </div>
      </Menu.Item>
    </Menu>
  );

  const information = (
    <Menu onClick={(e) => setSelected(e.key)}>
      <Menu.Item key="information">
        <div>
          <i className="feather-box" /> Information List
        </div>
      </Menu.Item>
      <Menu.Item key="informationCategory">
        <div>
          <i className="feather-list" /> Information Categories
        </div>
      </Menu.Item>
    </Menu>
  );

  const screenSetting = (
    <Menu onClick={(e) => setSelected(e.key)}>
      <Menu.Item key="screens">
        <div>
          <i className="feather-monitor" /> Screens
        </div>
      </Menu.Item>
      <Menu.Item key="screenSetting">
        <div>
          <i className="feather-monitor" /> Screen Settings
        </div>
      </Menu.Item>
      <Menu.Item key="configVariable">
        <div>
          <i className="feather-terminal" /> Config Variable
        </div>
      </Menu.Item>
      <Menu.Item key="screenConfiguration">
        <div>
          <i className="feather-settings" /> Screen Configuration
        </div>
      </Menu.Item>
      <Menu.Item key="fileManagement">
        <div>
          <i className="feather-file" /> File Management
        </div>
      </Menu.Item>
    </Menu>
  );

  const validateMenu = (crud) => {
    console.log(crud);
    if (typeof crud !== 'object') return crud;
    return Object.values(crud).includes(true);
  };

  return (
    <div className="siteinfo">
      <div className="siteinfo-nav">
        <div>
          <h3 className="title">{siteName}</h3>
          <span>{siteDescription && siteDescription}</span>
        </div>
        {/* <button onClick={handleBack} className="button-primary">
          Back
        </button> */}
      </div>

      {userData && userData.role === 'superadmin' ? (
        <Tabs activeKey={activeTab} onTabClick={handleChangeTab}>
          <TabPane
            tab={
              <Dropdown overlay={media} placement="bottomCenter">
                <div onClick={(e) => e.preventDefault()}>
                  <i className="feather-image" /> Media <DownOutlined />
                </div>
              </Dropdown>
            }
            key={activeTab === 'mediaCategory' ? 'mediaCategory' : 'media'}
          >
            <div className="tab-content">
              {activeTab === 'media' && <MediaLibrary />}
              {activeTab === 'mediaCategory' && <MediaCategories />}
            </div>
          </TabPane>
          <TabPane
            tab={
              <Dropdown overlay={poi} placement="bottomCenter">
                <div onClick={(e) => e.preventDefault()}>
                  <i className="feather-box" /> POI <DownOutlined />
                </div>
              </Dropdown>
            }
            key={
              activeTab === 'poiCategory'
                ? 'poiCategory'
                : activeTab === 'floor'
                ? 'floor'
                : 'poi'
            }
          >
            <div className="tab-content">
              {activeTab === 'poi' && <Pois />}
              {activeTab === 'poiCategory' && <Categories />}
              {activeTab === 'floor' && <Floors />}
            </div>
          </TabPane>

          <TabPane
            tab={
              <Dropdown overlay={information} placement="bottomCenter">
                <div onClick={(e) => e.preventDefault()}>
                  <i className="feather-info" /> Information <DownOutlined />
                </div>
              </Dropdown>
            }
            key={
              activeTab === 'informationCategory'
                ? 'informationCategory'
                : 'information'
            }
          >
            <div className="tab-content">
              {activeTab === 'information' && <Information />}
              {activeTab === 'informationCategory' && <InformationCategories />}
            </div>
          </TabPane>

          <TabPane
            tab={
              <>
                <i className="feather-map" /> Map
              </>
            }
            key="map"
          >
            <div className="tab-content">{activeTab === 'map' && <Maps />}</div>
          </TabPane>

          <TabPane
            tab={
              <Dropdown overlay={screenSetting} placement="bottomCenter">
                <div onClick={(e) => e.preventDefault()}>
                  <i className="feather-monitor" /> Screens
                  <DownOutlined />
                </div>
              </Dropdown>
            }
            key={
              activeTab === 'screenConfiguration'
                ? 'screenConfiguration'
                : activeTab === 'configVariable'
                ? 'configVariable'
                : activeTab === 'screenSetting'
                ? 'screenSetting'
                : activeTab === 'fileManagement'
                ? 'fileManagement'
                : 'screens'
            }
          >
            <div className="tab-content">
              {activeTab === 'screens' && <Screens />}
              {activeTab === 'screenSetting' && (
                <ScreenSettings handleChangeTab={handleChangeTab} />
              )}
              {activeTab === 'configVariable' && (
                <ConfigVariable handleChangeTab={handleChangeTab} />
              )}
              {activeTab === 'screenConfiguration' && (
                <ScreenConfiguration handleChangeTab={handleChangeTab} />
              )}
              {activeTab === 'fileManagement' && (
                <FileManagement handleChangeTab={handleChangeTab} />
              )}
            </div>
          </TabPane>

          {/* <TabPane
            tab={
              <>
                <i className="feather-settings" /> Screen Settings
              </>
            }
            key="screen"
          >
            <div className="tab-content">
              {activeTab === 'screen' && <ScreenSettings />}
            </div>
          </TabPane> */}
          {/* <TabPane
            tab={
              <>
                <i className="feather-rss" /> Feedback
              </>
            }
            key="feedback"
          >
            <div className="tab-content">
              {activeTab === 'feedback' && <Feedbacks />}
            </div>
          </TabPane> */}
        </Tabs>
      ) : userData.role === 'user' || userData.role === 'admin' ? (
        <Tabs defaultActiveKey={activeTab} onTabClick={handleChangeTab}>
          {grantAccess && validateMenu(grantAccess.media) && (
            <TabPane
              tab={
                <Dropdown overlay={media} placement="bottomCenter">
                  <div onClick={(e) => e.preventDefault()}>
                    <i className="feather-image" /> Media <DownOutlined />
                  </div>
                </Dropdown>
              }
              key={activeTab === 'mediaCategory' ? 'mediaCategory' : 'media'}
            >
              <div className="tab-content">
                {activeTab === 'media' && <MediaLibrary />}
                {activeTab === 'mediaCategory' && <MediaCategories />}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.poi) && (
            <TabPane
              tab={
                <Dropdown overlay={poi} placement="bottomCenter">
                  <div onClick={(e) => e.preventDefault()}>
                    <i className="feather-box" /> POI <DownOutlined />
                  </div>
                </Dropdown>
              }
              key={
                activeTab === 'poiCategory'
                  ? 'poiCategory'
                  : activeTab === 'floor'
                  ? 'floor'
                  : 'poi'
              }
            >
              <div className="tab-content">
                {activeTab === 'poi' && <Pois />}
                {activeTab === 'poiCategory' && <Categories />}
                {activeTab === 'floor' && <Floors />}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.information) && (
            <TabPane
              tab={
                <Dropdown overlay={information} placement="bottomCenter">
                  <div onClick={(e) => e.preventDefault()}>
                    <i className="feather-info" /> Information <DownOutlined />
                  </div>
                </Dropdown>
              }
              key={
                activeTab === 'informationCategory'
                  ? 'informationCategory'
                  : 'information'
              }
            >
              <div className="tab-content">
                {activeTab === 'information' && <Information />}
                {activeTab === 'informationCategory' && (
                  <InformationCategories />
                )}
              </div>
            </TabPane>
          )}

          {grantAccess && validateMenu(grantAccess.map) && (
            <TabPane
              tab={
                <>
                  <i className="feather-map" /> Map
                </>
              }
              key="map"
            >
              <div className="tab-content">
                {activeTab === 'map' && <Maps />}
              </div>
            </TabPane>
          )}

          {/* {grantAccess && validateMenu(grantAccess.feedback) && (
            <TabPane
              tab={
                <>
                  <i className="feather-rss" /> Feedback
                </>
              }
              key="feedback"
            >
              <div className="tab-content">
                {activeTab === 'feedback' && <Feedbacks />}
              </div>
            </TabPane>
          )} */}
        </Tabs>
      ) : null}

      {/* <div className="bottom-nav">
        <button
          onClick={handleBack}
          className="button-primary siteinfo-back-button"
        >
          Back
        </button>
      </div> */}
    </div>
  );
}

export default SiteInfo;
